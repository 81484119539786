// This file is the source file for: https://docs.google.com/spreadsheets/d/13FODLia-TwZoE1XRSr7YiPgT4wt1SEIczgjKPcDNtA4

/* eslint-disable no-useless-escape */

export const de = {
  language: 'de',
  finalized: 'Abgeschlossen',
  finalizedHint:
    'Abgeschlossene Veranstaltungen können nicht mehr einschreiben & abmelden (Abrechnung gemacht)',
  errorCodes: {
    approvedLicenseNotFound: 'Bestätigte Lizenz nicht gefunden',
    cannotDeleteLicenseWithExistingInscriptions:
      'Lizenzen mit bestehenden Einschreiben können nicht gelöscht werden. Bitte lösche zuerst die Einschreiben / Anmeldungen',
  },
  successful: 'Erfolgreich',
  anUnknownErrorOccurred: 'Ein unbekannter Fehler ist aufgetreten',
  lastChange: 'Letzte Änderung',
  inscriptionsDisabled: {
    title: 'Anmeldungen / Abmeldungen für diese Kategorie sind deaktiviert',
    hintEnlist: 'Feld ist bereits voll - eine andere Kategorie hat evt. noch Platz',
    hintUnlist:
      'Das Feld ist voll und neue Anmeldungen waren nicht mehr möglich. Aus diesem Grund sind die Abmeldungen ebenfalls deaktiviert',
    short: 'Einschreiben deaktiviert',
  },
  showDeleted: 'Gelöschte anzeigen',
  combine: 'Kombinieren',
  showOnlyDuplicates: 'Nur Duplikate anzeigen',
  newVersion: {
    available: 'Eine neue Version ist verfügbar',
    reload: 'Neu laden',
  },
  dashboard: {
    title: 'Dashboard',
    adminTitle: 'Admin Dashboard',
    licenses: 'Lizenzen',
    users: 'Benutzer',
    other: 'Sonstiges',
  },
  extended: 'Erweitert',
  adminArea: 'Adminbereich',
  toAdminArea: 'Zum Adminbereich',
  done: 'erledigt',
  email: 'E-Mail',
  emailFirebase: 'E-Mail Firebase',
  emailRacemanager: 'E-Mail Racemanager',
  active: 'Aktiv',
  name: 'Name',
  firstName: 'Vorname',
  lastName: 'Nachname',
  hintForName:
    'Der Name kann nach Absenden des Lizenzgesuchs nicht mehr geändert werden. Wenn du für eine andere Person ein Lizenzgesuch einreichen möchtest, musst du dich mit einer anderen Email-Adresse anmelden.',
  street: 'Strasse',
  zip: 'Postleitzahl',
  place: 'Ort',
  startsAt: 'Start',
  endsAt: 'Ende',
  alternativeStartsAt: 'Start (Alt.)',
  alternativeEndsAt: 'Ende (Alt.)',
  licensesAndDrafts: 'Lizenzen / Lizenzgesuche',
  categoryIds: 'Kategorien',
  categoryDate: 'Einschreibungsdatum',
  categoryDateUnknown: 'Gem. Tagesprogramm',
  offersPower: 'Stellt Strom zur Verfügung',
  cancelled: 'Abgesagt',
  lastFiveScansOnThisDevice: 'Letzte Scans am',
  suggestedDonationAmount: 'Vorgeschlagener Spendenbetrag bei Absage',
  unlist: {
    buttonText: 'Abmelden / ich starte nicht',
    dialogTitle: 'Wirklich abmelden',
    inProgress: 'Abmelden läuft...',
    dialogConfirmation: 'Vom Rennen abmelden und Startplatz aufgeben',
    dialogConfirmWithoutDonation: 'Wirklich abmelden',
    donationAmount: 'Spendebetrag',
    dialogConfirmDonation: (amount: string) => `Wirklich abmelden und CHF ${amount} spenden`,
    donation: (amount: string) =>
      `Das Startgeld und Tageslizenz wird dir zurück auf das Racemanager Konto überwiesen. Wir wären aber froh um eine Spende von CHF  ${amount}, um einen Teil der Kosten des Veranstalters zu decken. Die Spende wird dir automatisch vom Racemanager Konto abgezogen. Du kannst den freiwilligen Betrag nach eigenem Ermessen nach oben oder unten anpassen. Wenn du nichts spenden willst, kannst du den Betrag auf 0 setzen. Herzlichen Dank für deine Solidarität!`,
  },
  country: 'Land',
  birthdate: 'Geburtsdatum',
  birthdateDe: 'Geburtsdatum (DE)',
  birthdateTooYoung:
    'Du bist zu jung, um ein Lizenzgesuch zu stellen (oder das Geburtsdatum stimmt noch nicht)',
  phone: 'Telefon',
  comment: 'Kommentar',
  licenseType: 'Lizenztyp',
  licenseTypes: {
    national: 'National',
    international: 'International',
  },
  licenseTypesLong: {
    national: 'Nationale Lizenz',
    international: 'Internationale Lizenz',
  },
  newSamMember: {
    label: 'Ich bin noch nicht SAM-Mitglied',
    labelAlternative: 'Neues SAM-Mitglied?',
    validation: 'SAM-Mitgliedschaft',
    hintForNewMembers: 'Dieses Gesuch gilt als SAM-Anmeldung',
    hintForFine:
      'Falls du bereits Mitglied bist, und die Mitgliedernummer nicht angibst, wird ein Unkostenbeitrag von CHF 20 erhoben. Die 5- oder 6-stellige Mitgliedernummer (nicht Lizenznummer) findest du auf deinem SAM-Ausweis.',
  },
  samMemberNumber: {
    label: 'SAM-Mitgliedernummer',
    helperText:
      'Die 5- oder 6-stellige Mitgliedernummer (nicht Lizenznummer) findest du auf deinem SAM-Ausweis.',
    requiredHelperText:
      'Die Mitgliedernummer muss angegeben werden. Du findest die 5- oder 6-stellige Mitgliedernummer (nicht Lizenznummer) auf deinem SAM-Ausweis.',
    hintForSubmittedLicense:
      'Die Mitgliedernummer kann nach Absenden des Lizenzgesuchs nicht mehr geändert werden. Wenn du für eine andere Person ein Lizenzgesuch einreichen möchtest, musst du dich mit einer anderen Email-Adresse anmelden.',
  },
  password: 'Passwort',
  setPassword: 'Neues Passwort setzen',
  confirmSetPassword: 'Passwort setzen',
  licenseNumber: 'Lizenznummer',
  afmSection: {
    label: 'AFM-Club',
    labelOptional: 'AFM-Club (optional)',
  },
  fmsSection: {
    label: 'Swiss Moto Sektion',
    labelOptional: 'Swiss Moto Sektion (optional)',
  },
  samSection: {
    label: 'SAM-Sektion',
    helperText: 'Bitte wähle die Sektion aus, der du beitreten möchtest',
    hintForSubmittedLicense:
      'Die SAM-Sektion kann auf dieser Plattform nicht geändert werden. Bitte beantrage den Sektionswechsel hier:',
  },
  sidecarPartner: {
    label: 'Seitenwagen Partner',
    hint: 'Name, Vorname, Geburtsdatum des Partners (zur Identifikation des Gespanns)',
    validation: 'Seitenwagen Partner',
  },
  assignLicense: {
    title: 'Lizenz erfassen',
    onlyRegisteredRiders:
      'Lizenzen können nur für bereits registrierte Fahrer erfasst werden. Falls du einen neuen Fahrer anlegen möchtest, musst du dich als Admin abmelden, und mit der Email des Fahrers anmelden',
    tooManyResults: {
      title: 'Zu viele Ergebnisse',
      text: 'Bitte schränke die Suche weiter ein, damit eine Auswahl an Fahrern angezeigt wird (z.B. via Email, Vorname, Nachname, Strasse, PLZ, Ort, Geburtsdatum, ...)',
    },
  },
  licenses: {
    drafts: 'Pendente Lizenzgesuche',
    approved: 'bestätigte Lizenzgesuche',
    approvedLicenses: 'Bestätigte Lizenzen',
    unapprovedLicenses: 'Unbestätigte Lizenzen',
    confirm: 'bestätigen',
    edit: 'bearbeiten',
    editLicenseBooking: 'Lizenzbuchung bearbeiten',
    searchLicense: 'Suchen (Name, Ort, PLZ, Mitgliedernummer)',
    licenseRequests: 'Lizenzanmeldungen',
    licenseRequestsByCategoryType: 'Sparten Lizenzanmeldungen',
    licenseRequestsByCategory: 'Kategorien Lizenzanmeldungen',
    confirmed: 'Bestätigt',
    unconfirmed: 'Unbestätigt',
    totalLicenseRequests: 'Total Lizenzgesuche',
    assignTransponder: 'Transponder zuweisen',
    confirmLicense: 'Lizenz bestätigen',
    cancelledBookingsError:
      'Fehler: Stornierte buchungen dürfen nicht mehrfach storniert werden. Dieser Fall muss noch abgehandelt / fertig implementiert werden. Sorry :-(',
    importLicenses: 'Lizenzen importieren',
    licenseNotFound: 'Lizenz nicht gefunden',
    licenseIdOrCodeMissing: 'Lizenz ID oder Code fehlt',
  },
  routes: {
    termsAndDataProtection: 'Impressum & Datenschutz',
    licenseTerms: 'Lizenzbedingungen',
    insuranceTerms: 'Versicherungsbedingungen',
    homepage: 'Webauftritt',
    homepages: 'Webauftritte',
    dashboard: 'Dashboard',
    userDashboard: 'Zum Dashboard aus Benutzersicht',
    adminDashboard: 'Adminbereich',
    allTransactions: 'Alle Transaktionen',
    dashboardAllTransactions: 'KPIs',
    manualPayments: 'Manuelle Zahlungen',
    approvedLicenses: 'Bestätigte Lizenzen',
    assignLicense: 'Lizenz erfassen',
    userFinancialsRiders: 'Kontostände Fahrer',
    userFinancials: 'Kontostände',
    licenseDrafts: 'Lizenzgesuche',
    newTransponders: 'Transponderbestellungen',
    licenseBookingsByRider: 'Lizenzbuchungen nach Fahrer',
    allLicenseBookings: 'KPIs Lizenzbuchungen',
    insurances: 'Versicherungen',
    allLicenses: 'KPIs Lizenzen',
    payments: 'Zahlungen',
    memberFees: 'Mitgliederbeiträge',
    manualBookingsAndCharges: 'Manuelle Gutschriften & Belastungen',
    manualBookings: 'Manuelle Buchungen',
    categoriesKeyFigures: 'Statistik Lizenzen',
    associationFinancials: 'Verbandsabrechnungen',
    importLicenses: 'Lizenzen importieren',
    userEvents: 'Benutzeraktivität',
    newInscription: 'Zum Einschreiben für',
    publicInscriptions: 'Startlisten',
    inscriptionGroups: 'Gruppeneinteilungen',
    lonelyTwoDayDiscounts: 'Alleinstehende Zwei-Tages-Rabatte',
    userAccounts: 'Benutzerkonten',
    emailConflicts: 'E-Mail Konflikte',
    transactionsWithoutUser: 'Transaktionen ohne Benutzer',
    duplicatedUsers: 'Duplizierte Accounts',
    dbMigrations: 'DB Migrationen',
    administrators: 'Administratoren',
    messages: 'Nachrichten',
    changeYear: 'Jahr wechseln',
    qrPitLane: 'Scanner Boxenkarte',
    previewLicense: 'Lizenzvorschau',
    qrLicense: 'Scanner Lizenz',
    editProfile: 'Profil bearbeiten',
    editEmergency: 'Notfallblatt bearbeiten',
    editDriversLicense: 'Führerausweis bearbeiten',
    editHealthCheck: 'Gesundheitscheck bearbeiten',
    editInsurance: 'Versicherung bearbeiten',
    editPhoto: 'Foto bearbeiten',
    editTransponder: 'Transponder bearbeiten',
    changeEmailAfterLogin: 'E-Mail ändern',
    newLicense: 'Neue Lizenz beantragen',
  },
  lastYear: 'Letztes Jahr',
  noPreviousLicense: 'Ich hatte letztes Jahr keine Lizenz',
  addNewLicense: 'Neue Lizenz erfassen',
  editLicenseRequest: (categoryNameWithNumber: string) =>
    `Lizenzgesuch ${categoryNameWithNumber} bearbeiten`,
  editLicense: (categoryNameWithNumber: string) => `Lizenz ${categoryNameWithNumber} bearbeiten`,
  acceptLicenseConditions: 'Lizenzbedingungen akzeptieren',
  conditionsAcceptedByRider: 'Bedingungen und Datenschutzerklärungen sind vom Fahrer akzeptiert',
  noLicenseRequestAlert:
    'Der Fahrer hat noch kein Lizenzgesuch abgesendet, und somit die Lizenzbedingungen möglicherweise noch nicht akzeptiert. Der Fahrer sollte das erste Lizenzgesuch selber absenden, damit er die Lizenzbedingungen lesen und akzeptieren kann.',
  licenseRequestAlternative:
    'Falls dies nicht möglich / nötig ist, bitte stelle eines der folgenden Punkten sicher:',
  licenseRequestAdminConfirmation: 'Du hast den Fahrer auf die Lizenzbedingungen aufmerksam gemacht',
  licenseRequestRiderConfirmation:
    'Er/sie hat die Lizenzbedingungen bei dir schriftlich oder per E-Mail bestätigt',
  licenseRequestOtherConfirmation:
    'Der Fahrer hat die Lizenzbedingungen bereits anderweitig akzeptiert (z.b. indem er sich mit einem anderen Account bereits eine Lizenz gelöst hat)',
  licenseRequestInternalNote:
    'Interne Bemerkung: Wann und auf welchem Weg hat der Fahrer die Bedingungen und die Datenschutzerklärungen akzeptiert?',
  editLicenseHelperText: 'Bsp. 26.1.2021, 20:30, per E-Mail an lizenzen@s-a-m.ch',
  license: 'Lizenz',
  addLicense: 'Lizenz erfassen',
  saveLicenseRequest: 'Lizenzgesuch speichern',
  confirmLicenseRequest: (categoryNameWithNumber: string) =>
    `Lizenzgesuch ${categoryNameWithNumber} bestätigen`,
  licenseConfirmed: 'Lizenz bestätigt',
  licenseRequestSummary: 'Lizenzgesuch Zusammenfassung',
  licensesAndLicenseRequests: 'Lizenzen / Lizenzgesuche',
  confirmedLicenseForRider: 'Bestätigte Lizenzen dieser Person',
  otherLicensesWithSameNumber: 'Andere Lizenzen mit gleicher Startnummer',
  otherLicenseRequestsForRider: 'Andere Lizenzanträge dieser Person',
  or: '-- oder --',
  lastYearLicenses: 'Lizenzen letztes Jahr (SAM, Swiss Moto, MXRS, AFM, ...)',
  lastYearLicensesShort: 'Lizenzen letztes Jahr',
  addCategory: 'Kategorie hinzufügen',
  lastYearCategories: 'Kategorien letztes Jahr (SAM National B, MXRS Race, ...)',
  lastYearCategoriesShort: 'Kategorien letztes Jahr',
  lastYearStartNumbers: 'Startnummern letztes Jahr',
  lastYearRanks: 'Jahres-Schlussränge letztes Jahr',
  category: 'Kategorie',
  categoryType: 'Sparte',
  sponsor: 'Team / Sponsor',
  bike: 'Motorrad',
  bikes: 'Motorräder',
  maxTwoBikes: 'Es können maximal 2 Motorräder gewählt werden',
  chooseBikes: 'Motorräder wählen',
  section: 'Sektion / Verband',
  domicile: 'Domizil',
  nationality: 'Nationalität',
  association: 'Verband',
  changeAssociation: 'Verband wechseln',
  categoriesAlt: 'Kategorien',
  dayCategories: 'Tages-Kategorien',
  dayCategory: {
    title: 'Tageskategorie',
    transponder: 'Transponder',
    name: 'Name',
    hint: 'Bemerkungen',
    additionalTermsText: 'Zusätzliche Bedingungen',
    additionalTermsURL: 'URL zu zusätzlichen Bedingungen',
    price: 'Startgeld',
    needsInsurance: 'Benötigt Versicherungsnachweis',
    needsEmergency: 'Benötigt Notfallblatt',
    needsHealthCheck: 'Benötigt Gesundheits-Check',
    needsBikeInfo: 'Benötigt Motorrad-Info',
    advertiseSamInsurance: 'Auf Versicherungsschutz via SAM hinweisen',
    myLapsName: 'Name Zeitmessung',
    myLapsNameIfDifferent: 'Name Zeitmessung (falls anders als Name)',
    sidecar: 'Seitenwagen-Kategorie',
    sidecarPassenger: 'Seitenwagen-Passenger-Kategorie',
    surcharge: 'Zuschlag (wird dem Preis zugeschlagen)',
    surchargeAfter: 'Zuschlag ab dem (Datum im ISO-Format, z.B. "2022-01-16T18:13:09.197Z")',
    discount: 'Rabatt (wird vom Preis abgezogen)',
    discountUntil: 'Rabatt bis (Datum im ISO-Format, z.B. "2022-01-16T18:13:09.197Z")',
    inscriptionsLimit: 'Einschreibe-Limit (z.B. 45)',
    waitlistLimit: 'Waitlist Limit (z.B. 30)',
    pricePower: 'Preis Strom',
    priceTransponderRental: 'Preis Transpondermiete',
    sortPrimaryBy: 'Sortierung (Primär)',
    sortPrimaryByNames: {
      paymentDate: 'Zahlungsdatum',
      enlistingDate: 'Anmeldedatum',
    },
    createDayCategory: 'Tageskategorie hinzufügen',
    deleteDayCategory: 'Tageskategorie löschen',
    deleteDayCategoryConfirmation:
      'Wirklich löschen? Bestehende Einschreiben zu dieser Kategorie werden automatisch gelöscht.',
  },
  categories: (licenseYear: number) => `Erwünschte Kategorien für ${licenseYear}`,
  categoriesWithoutYear: 'Erwünschte Kategorien',
  categoryAlreadyChosenHint: 'Kategorie ist bereits über anderen Verband gewählt',
  categoryNotOnlineYet: 'Kategorie ist noch nicht aufgeschaltet. Bitte versuche es später nochmal.',
  licenseAlreadyConfirmed: 'Lizenz ist bereits bestätigt',
  sportEvent: 'Veranstaltung',
  sportEvents: 'Veranstaltungen',
  sportEventType: 'Veranstaltungstyp',
  sportEventTypes: {
    '': 'Alle',
    enduro: 'Enduro',
  },
  sportEventTypeHint: 'Bei Enduro-Veranstaltungen sind Nummerschild und Führerausweis obligatorisch',
  sportEventStatus: {
    online: 'Online',
    draft: 'Entwurf',
    deleted: 'Gelöscht',
  },
  sportEventFormErrors: {
    invalidStartOrEndDate: 'Datum fehlerhaft: bitte überprüfe Start und Ende der Veranstaltung',
    setDateOrAnnounceLater:
      'Datum der Kategorien fehlerhaft: entweder wird das Datum später bekanntgegeben, oder das Datum ist gesetzt, nicht beides zusammen',
    noDateForCategory:
      'Datum der Kategorien fehlerhaft: es muss mindestens ein Datum pro Kategorie gewählt werden',
    dateOutsideOfEventDates:
      'Datum der Kategorien fehlerhaft: es dürfen nur Daten gewählt, die während der Veranstaltung liegen',
  },
  hasTransponder: {
    label: 'Ich habe bereits einen MyLaps Transponder',
    labelAlternative: 'Besitzt MyLaps Transponder',
    validation: 'Transponder',
    hintForNewTransponder: 'Ich bestelle einen MyLaps Transponder',
  },
  orderedMyLapsTransponder: {
    labelSingular: 'Ich bestelle folgenden Transponder',
    labelPlural: 'Ich bestelle folgende Transponder',
    labelAlternative: 'Bestellte Transponder',
    validation: 'Transponder',
  },
  transponders: {
    title: 'Transponder',
    transponderSystem: 'Transpondersystem',
    privateTransponder: 'Privattransponder',
    borrowedTransponder: 'Leihtransponder',
    transponder: 'Transponder',
    transponders: 'Transponder',
    hasTransponder: 'Transponder vorhanden',
    transpondersForm: {
      yourTranspondersTitle: 'Deine Transponder',
      buyTransponderTitle: 'Transponder kaufen',
      usedTransponders: 'Eingesetzte Transponder',
      perCategory: 'Je nach Kategorie und Reglement muss',
      needsToBeBought: 'ein eigener Transponder gekauft werden',
      needsToBeRentedPerEvent: 'wird ein Transponder pro Veranstaltung kostenpflichtig verliehen',
      isGivenForFreePerEvent: 'wird ein Transponder pro Veranstaltung kostenlos verliehen',
      pleaseInformYourself:
        'Bitte informiere dich im entsprechenden Reglement über die Handhabung, wenn du keinen Transponder hast und keinen kaufen möchtest.',
      buyAdvertisment:
        'Das bequemste ist in jedem Fall, einen eigenen Transponder zu bestellen und verwenden. Das kannst du hier:',
      batteryReminder: 'Die Batterie des RF Chronolec Transponders muss alle 3 Jahre ersetzt werden',
      canBeEditedLater: 'Du kannst deine Transponder später jederzeit bearbeiten',
    },
    transponderNames: {
      subscriptionOneYear:
        'MyLaps X2 / TR2 MX, 1-Jahresabo, Motocross SAM/MXRS/SJMCC und Supermoto SAM/Swiss Moto',
      subscription:
        'MyLaps X2 / TR2 MX, 5-Jahresabo, Motocross SAM/MXRS/SJMCC und Supermoto SAM/Swiss Moto',
      purchase: 'MyLaps TR2, Kaufvariante, Motocross SAM/MXRS/SJMCC und Supermoto SAM/Swiss Moto',
      rfPurchase: 'Chronolec RF (via Swiss Moto), Kaufvariante, Motocross AFM/Swiss Moto',
      rfPurchaseAfm: 'Chronolec RF (via AFM), Kaufvariante, Motocross AFM/Swiss Moto',
    },
    editTransponder: 'Transponder bearbeiten',
    orderOptionalTransponder: 'Ich möchte einen Transponder angeben oder bestellen',
    transponderOrders: 'Transponderbestellungen',
    orderedTransponderIds: 'Bestellte Transponder IDs',
    orderedTransponders: 'Bestellte Transponder',
  },
  transponderMake: {
    label: 'Transponder: Marke & Typ (z.B. MyLaps X1)',
    labelAlternative: 'Transponder: Marke & Typ',
  },
  transponderNumber: {
    label: 'MyLaps Transpondernummer',
    labelInscription: 'MyLaps Transpondernummer (persönlicher Transponder)',
  },
  transponderNumbers: {
    label: 'MyLaps Transpondernummern',
  },
  transponderNumbersInput: {
    label: 'MyLaps Transpondernummern (kommagetrennt, z.b. 123, 456)',
  },
  hasTransponderRf: {
    label: 'Ich habe bereits einen Chronolec RF Transponder',
    labelAlternative: 'Besitzt RF Transponder',
    validation: 'Transponder',
  },
  transponderNumberRf: {
    label: 'Chronolec RF Transpondernummer',
  },
  transponderNumbersRf: {
    label: 'Chronolec RF Transpondernummern',
  },
  transponderNumbersRfInput: {
    label: 'Chronolec RF Transpondernummern (kommagetrennt, z.b. 123, 456)',
  },
  bikeMake: {
    label: 'Maschinenmarke (z.B. Kawasaki)',
    labelShort: 'Maschinenmarke',
  },
  numberPlate: {
    label: 'Nummerschild',
    hint: 'z.B. ZH 12345',
  },
  bikeInsurance: {
    label: 'Motorradversicherung',
    hint: 'z.B. Allianz',
  },
  bikeModel: {
    label: 'Maschinenmodell (z.B. KX450)',
    labelShort: 'Maschinenmodell',
    hint: 'Falls aus Modell nicht ersichtlich oder nicht original: zusätzlich Hubraum [ccm]',
  },
  frameNumber: 'Rahmennummer',
  bikeCylinderDisplacement: 'Hubraum [ccm]',
  preferredNumber: {
    label: 'Wunschnummer(n)',
    labelAlternative: 'Startnummer',
    helperText: 'z.B. "7, oder zweistellig mit der Ziffer 7"',
  },
  issuedNumber: {
    label: 'Definitive Startnummer',
    labelAlternative: 'Startnummer',
  },
  ahvNumber: 'AHV-Nummer / Sozialversicherungsnummer',
  civilStatus: {
    label: 'Zivilstand',
    single: 'Ledig',
    married: 'Verheiratet',
    divorced: 'Geschieden',
    widowed: 'Verwitwet',
    registeredPartnership: 'Eingetragene Partnerschaft',
    other: 'Weitere',
  },
  confession: 'Konfession',
  profession: 'Beruf',
  doctorName: 'Hausarzt',
  doctorPhone: 'Telefon Hausarzt',
  employerName: 'Arbeitgeber',
  employerPhone: 'Telefon Arbeitgeber',
  accidentInsurance: 'Unfallversicherung',
  accidentInsuranceName: 'Unfallversicherung',
  accidentInsurancePolicyNumber: 'Policen Nr. Unfallversicherung',
  accidentInsurancePlan: {
    label: 'Spital Abteilung Unfallversicherung',
    general: 'Allgemein',
    halfPrivate: 'Halbprivat',
    private: 'Privat',
    validation: 'Bitte wähle eine Spital Abteilung Unfallversicherung',
  },
  healthInsurance: 'Krankenkassenversicherung',
  healthInsuranceName: 'Krankenkasse',
  healthInsurancePolicyNumber: 'Policen Nr. Krankenkasse',
  healthInsurancePlan: {
    label: 'Spital Abteilung Krankenkasse',
    general: 'Allgemein',
    halfPrivate: 'Halbprivat',
    private: 'Privat',
    validation: 'Bitte wähle eine Spital Abteilung Krankenkasse',
  },
  additionalInsurances: 'Zusatzversicherung',
  hasSupplementaryInsurance: {
    label: 'Ich habe eine Zusatzversicherung',
    validation: 'Zusatzversicherung',
    hintForSupplementaryInsurance:
      'In der SAM-Lizenz ist ist ein Zusatz inbegriffen, und falls du eine SAM-Lizenz gelöst hast, ist eine Zusatzversicherung daher möglicherweise nicht mehr nötig',
  },
  hasReturnTransportInsurance: {
    label: 'Ich habe eine Rücktransportversicherung',
    validation: 'Rücktransportversicherung',
    hintForNewReturnTransportInsurance: 'Ich habe keine Rücktransportversicherung',
  },
  returnTransportInsuranceName: 'Name Rücktransportversicherung (z.B. Rega)',
  contactOnAccident: 'Bei Unfall benachrichtigen',
  contactOnAccident1Name: 'Person 1: Name',
  contactOnAccident1Phone: 'Person 1: Telefon',
  contactOnAccident2Name: 'Person 2: Name',
  contactOnAccident2Phone: 'Person 2: Telefon',
  medicalInformation: 'Medizinische Informationen',
  medication: 'Aktuelle Dauermedikamente',
  allergies: 'Allfällige Allergien gegen Medikamente',
  infoForDoctor: 'Sonstige Infos für Rettungsdienst / Arzt',
  additionalInformation:
    'Folgende Angaben können freiwillig gemacht werden und werden streng vertraulich behandelt:',
  bloodGroup: 'Blutgruppe',
  bloodRhesusFactor: 'Rhesus-Faktor',
  specialInformation: 'Weitere spezielle Angaben',
  confirmRevokeConfidentiality: {
    label:
      'Ich befreie Arzt, Rettungsdienst und Spital bei einem Zwischenfall von der ärztlichen Schweigepflicht gegenüber Mitgliedern der Rettungskette, der Sportkommission, OK-Präsident, Rennleitung und Notfallkontakt',
    validation: 'Die Bedingungen müssen akzeptiert werden, um Rennen zu fahren',
  },
  remarks: 'Bemerkungen',
  remarksRider: 'Bemerkungen Fahrer',
  remarksAdmin: 'Bemerkungen Admin',
  licenseRequestSent: 'Lizenzgesuch abgesendet am',
  internalRemarks: 'Interne Bemerkungen',
  parentsInfo: 'Vorname, Nachname, Telefon des erziehungsberechtigen Person',
  parentsAgree: {
    label: 'Dieses Formular wurde von der erziehungsberechtigten Person ausgefüllt',
    validation: 'Dieses Formular muss von der erziehungsberechtigen Person ausgefüllt werden',
  },
  acceptTerms: {
    label: 'Ich habe die Bedingungen und die Datenschutzerklärung gelesen und stimme zu',
    validation: 'Bedingungen und Datenschutz',
    additionalTerms: 'Zusätzliche Bedingungen',
    hintForTerms: 'Lese die Dokumente hier:',
    hintForTermsTerms: 'Bedingungen & Datenschutzerklärung',
    hintForTermsDataProtection: 'Datenschutzerklärung',
    hintForTermsError:
      'Die Bedingungen und Datenschutzerklärung müssen akzeptiert werden, um eine Lizenz zu lösen',
  },
  photoPath: 'Foto (nur Personen ohne Vorjahreslizenz)',
  riderWeight: 'Gewicht Pilot fahrfertig',
  bikeName: {
    label: 'Name des Motorrads (optional)',
    hint: 'Für dich zur Identifikation, z.b. "Rennmotorrad", "Ersatz" oder "Bike 2020"',
  },
  teamName: {
    label: 'Team (optional)',
    labelShort: 'Team',
  },
  bikeFrame: 'Rahmen Fahrgestellmarke / Typ',
  bikeMotorName: 'Motorenmarke / Motorname',
  bikeMotorNumber: 'Motornummer (die ersten 6 Ziffern)',
  bikeIntake:
    'Einlasssystem (Schlitzgesteuert, ZME ab Werk [Zylindermembraneinlass], Umbau auf ZME, GME [Gehäusemembraneinlass])',
  bikeCylinderCooling: 'Zylindermarke (luftgekühlt, wassergekühlt)',
  bikeCylinderIsOriginal: 'Zylinder original? (ja, nein)',
  bikeIgnition: 'Zündanlage (Unterbrecher, elektronisch)',
  bikePistonSize: 'Kolbendurchmesser [mm]',
  bikeCarburetorSize: 'Vergasergrösse [mm]',
  bikeCrankshaftIsOriginal: 'Kurbelwellen-Hub (wie Motororiginal) [mm]',
  bikeCrankshaftModified: 'Kurbelwellen-Hub (nicht Motororiginal) [mm]',
  acceptBikeTerms: {
    label: (licenseYear: number) =>
      `Ich bestätige, dass meine Ausrüstung (z.B. Schutzbekleidung, Helm) und mein Motorrad dem entsprechenden Reglement ${licenseYear} meiner Kategorie entsprechen (bei Clubrennen gelten die entsprechenden Bestimmungen des Veranstalters). Ich bestätige, dass ich die volle Schutzbekleidung ausnahmslos in jedem Training und Rennen einer Veranstaltung tragen werde. Ich bestätige, dass ich für die Sicherheit meines Motorrads verantwortlich bin.`,
    validation:
      'Um deine Sicherheit und die Sicherheit deiner Mitfahrer zu erhöhen, musst du die entsprechenden Punkte einhalten, um Rennen zu fahren',
  },
  table: {
    oneEntryIsDisplayed: 'Ein Eintrag wird angezeigt',
    showingNEntries: (amount: number) => `${amount} Einträge werden angezeigt`,
    showAllEntries: (amount: number) => `Alle ${amount} Einträge anzeigen`,
    manyEntriesWarning:
      '(viele Einträge => Excel Export ist evt. besser geeignet, als die Daten hier anzuzeigen)',
  },
  associationPayments: {
    title: 'Verbandauszahlungen',
    add: 'Verbandauszahlung erfassen',
    delete: 'Verbandauszahlung löschen',
    downloadSelectedPayments: (amount: number) =>
      `${amount} ausgewählte Verbandauszahlungen herunterladen`,
    requestPayment: 'Auszahlung anfordern',
    states: {
      requested: 'Auszahlung angefordert',
      paid: 'Auszahlung erfolgt',
    },
  },
  accountBalance: 'Kontostand',
  accountBalanceAssociation: 'Kontostand Verband (gemäss Filter)',
  accountBalanceChangeAssociation: 'Veränderung Kontostand Verband',
  noAccountBalanceBookings: 'Keine Buchungen',
  addManualPaymentButtonText: (amount: string) => `${amount} einkassieren`,
  transactionsKPIs: 'Transaktionen KPIs',
  accountBalanceCHF: 'Kontostand in CHF',
  processedAt: 'Verarbeitet am',
  createdAt: 'Erstellt am',
  updatedAt: 'Bearbeitet am',
  actions: 'Aktionen',
  amount: {
    label: 'Betrag',
    hint: 'Negativ: Gutschrift, Positiv: Belastung',
  },
  amountOpen: 'Davon noch offen',
  amountOpenPosition: 'Offen',
  amountTable: 'Betrag',
  amountTableCHF: 'Betrag in CHF',
  sportEventFinancialsAmounts: {
    total: 'Total Einnahmen',
    inscription: 'Davon Startgeld',
    power: 'Davon Strom (Supermoto)',
    dayLicense: 'Davon Tageslizenz',
    donation: 'Davon Spende',
    inscriptionDiscount: 'Davon 2-Tages-Rabatt',
    open: 'Unbezahlte Einnahmen',
  },
  createBooking: 'Verbuchen',
  createBookingAndBill: 'Verbuchen und Rechnung erstellen',
  reverseBooking: 'Rückbuchen',
  transactionTypeNames: {
    licenseBooking: 'Lizenzbuchung',
    reverseLicenseBooking: 'Lizenzrückbuchung',
    inscriptionBooking: 'Einschreiben',
    manualBooking: 'Manuelle Buchung',
    payment: 'Automatische Zahlung',
    manualPayment: 'Manuelle Zahlung',
    bill: 'Rechnung',
    associationPayment: 'Zahlung an Verband',
  },
  lineItemNames: {
    inscriptionLineItem: 'Startgeld',
    inscriptionDayCategoryLineItem: 'Startgeld (Einzelveranstaltung)',
    inscriptionDayLicenseLineItem: 'Startgeld (Tageslizenz)',
    powerLineItem: 'Strom',
    inscriptionDayCategoryPowerLineItem: 'Strom',
    categoryLineItem: 'Kategorie',
    transponderLineItem: 'Transponder',
    donationLineItem: 'Spende',
    inscriptionDiscountLineItem: '2-Tages-Rabatt',
  },
  signIn: {
    popupOpened: 'Popup für die Anmeldung wurde geöffnet',
    signInWithGoogle: 'Anmelden mit Google',
    signInWithFacebook: 'Anmelden mit Facebook',
    emailSentLong:
      'E-Mail wurde versendet. Bitte prüfe deine Mails und klicke auf den Link, um dich anzumelden.',
    emailSentShort: 'E-Mail versendet',
    signInWithPassword: 'Anmelden mit Passwort',
    signInWithEmail: 'Anmelden mit E-Mail',
    backToSignIn: 'Zurück zur Anmeldung',
    emailNotSent: 'E-Mail wurde nicht übermittelt. Bitte andere Anmeldemöglichkeit nutzen.',
    verifyEmail: (email: string) => `E-Mail \"${email}\" verifizieren`,
    verificationEmailSent: (email: string) =>
      `E-Mail wurde versendet. Bitte prüfe deine Mails und klicke auf den Link, um \"${email}\" zu bestätigen.`,
    creatingProfile: 'Nur eine Sekunde, dein Profil wird erstellt...',
    loading: 'App wird geladen...',
    loadingUser: 'Benutzer wird geladen...',
    loadingDocuments: 'Dokumente werden geladen...',
    loadingUserData: (email: string) => `Benutzerdaten von ${email} werden geladen...`,
  },
  signInText: 'Anmelden',
  signOutText: 'Abmelden',
  inscribe: 'Einschreiben',
  inscribeFor: 'Einschreiben am',
  inscribeIn: 'Einschreiben in',
  inscribeAtDateUnknown: 'Einschreiben (Renntag(e) gemäss Tagesprogramm)',
  user: 'Fahrer',
  userID: 'Fahrer ID',
  admin: 'Admin',
  adminID: 'Admin ID',
  id: 'ID',
  datetime: 'Datum / Zeit',
  datetimeISO: 'Datum ISO',
  bookingType: 'Typ',
  bookingSubtype: 'Subtyp',
  bookingItemName: 'Buchungseintrag',
  bookingText: 'Buchungstext',
  manualPaymentTag: 'Tag (interner Filter)',
  manualBookingTag: 'Tag (interner Filter)',
  licenseStatus: 'Status',
  uid: 'UID',
  photo: 'Bild',
  displayName: 'Anzeigename',
  creationTime: 'Registriert am',
  lastSignInTime: 'Letzter Login',
  customClaims: 'Rollen',
  date: 'Datum',
  position: 'Position',
  reference: 'Referenz',
  referenceParts: 'yymmdd - hhmmss - Mitgliedernummer - Zufall - Checksumme (möglicherweise verschoben)',
  status: 'Status',
  open: 'Offen',
  paid: 'Bezahlt',
  importStatus: 'Import',
  parsingStatus: 'Parsing',
  sheetStatus: 'Änderung',
  details: 'Details',
  debitor: 'Einbezahlt von / für',
  bill: 'Rechnung',
  iban: 'IBAN',
  healthCheck: {
    title: 'Gesundheits-Check',
    status: {
      notUploaded: 'Nicht (vollständig) hochgeladen',
      uploaded: 'Hochgeladen',
      verified: 'Bestätigt',
      invalid: 'Beanstandet, bitte nochmal hochladen',
    },
    downloadAndPrintOptionA:
      'Option A: Internationale Lizenz (DE): Gesundheits-Check herunterladen und ausdrucken',
    downloadAndPrintOptionB:
      'Option B: Internationale Lizenz (FR): Gesundheits-Check herunterladen und ausdrucken',
    downloadAndPrintOptionC: 'Option C: Nationale Lizenz: Eigenerklärung herunterladen und ausdrucken',
    instructions: {
      fillInByDoctor: 'Vom Arzt ausfüllen und unterschreiben lassen',
      fillInPersonalDataAndSign: 'Personalien ausfüllen und unterschreiben',
    },
    takePictureOrUpload: 'Fotografieren / hochladen',
    page: 'Seite',
    deletePage: (page: number) => `Seite ${page} löschen`,
  },
  provideDocuments: {
    status: 'Status:',
    optionATakePictureWithPhone: 'Option A: Mit dem Telefon fotografieren (siehe unten)',
    optionBScanAndUploadOnComputer: 'Option B: Scannen und am Computer hochladen (siehe unten)',
    checkReadabilityAndSignature: 'Lesbarkeit und Unterschriften kontrollieren',
    qualityHint:
      'Bitte achte darauf, dass die Formulare gut lesbar / fotografiert sind. Falls wir die Formulare nicht lesen können, musst du sie später noch einmal hochladen',
    errorTooManyFilesSelected: 'Zu viele Dateien ausgewählt für Seite',
  },
  insurance: {
    title: 'Versicherungsbestätigung',
    shortTitle: 'Versicherung',
    status: {
      notUploaded: 'Nicht hochgeladen',
      uploaded: 'Hochgeladen',
      verified: 'Bestätigt',
      invalid: 'Beanstandet vom Admin, bitte nochmal hochladen',
    },
    chooseInsuranceConfirmationType: 'Versicherungsbestätigung wählen:',
    optionAForEmployees: 'Für Angestellte gemäss Schweizerischem UVG',
    optionBForSelfEmployed: 'Für selbstständig Erwerbende gemäss Schweizerischem UVG',
    optionCForOthers: 'Für alle anderen Personen',
    downloadInsuranceConfirmation:
      'Versicherungsbestätigung herunterladen und ausdrucken, oder elektronisch versenden',
    fillInByEmployer: 'Versicherungsbestätigung vom Arbeitgeber ausfüllen und unterschreiben lassen',
    uploadInsuranceConfirmation: 'Versicherungsbestätigung: Fotografieren / hochladen',
    deleteInsuranceConfirmation: 'Versicherungsbestätigung löschen',
  },
  pdfInsurance: {
    generatedOn: 'Generiert am',
    insuranceCompanyAndPoliceNumber:
      'Versicherungsgesellschaft, Policen-Nr. ..............................................................................................',
    fillInByEmployer: {
      title: 'Vom Arbeitgeber auszufüllen',
      companyAddress:
        'Firma / Anschrift .................................................................................................................................',
      confirmAccidentInsurance: (riderName: string, formattedBirthdate: string) =>
        `Hiermit bestätigen wir, dass ${riderName} ${formattedBirthdate} gegen Nichtbetriebsunfälle gemäss UVG versichert ist.`,
      datePlaceCompanySignature:
        'Datum, Ort, Unterschrift Firma ...........................................................................................................',
    },
    fillInByRider: {
      title: 'Vom Fahrer auszufüllen',
      confirmAccidentInsurance: (riderName: string, formattedBirthdate: string) =>
        `Hiermit bestätige ich, dass ich, ${riderName} ${formattedBirthdate} gegen Nichtbetriebsunfälle gemäss UVG versichert bin.`,
      datePlaceRiderSignature:
        'Datum, Ort, Unterschrift Fahrer ..........................................................................................................',
      confirmAccidentInsuranceOther: (riderName: string, formattedBirthdate: string) =>
        `Hiermit bestätige ich, dass ich, ${riderName} ${formattedBirthdate} gegen Unfälle äquivalent zur Schweizerischen UVG versichert bin. Im Speziellen sind die Heilungskosten bei einem Unfall durch meine Versicherung gedeckt.`,
    },
    selfDeclaration: {
      title: 'Lizenzgesuch | Eigenerklärung für Nationale Pilotenlizenz',
      confirmTitle: (riderName: string, formattedBirthdate: string) =>
        `Ich, ${riderName}, geboren am ${formattedBirthdate}, erkläre hiermit gemäss geltendem Recht:`,
      bulletPoint1:
        'Ich beantrage die Ausstellung einer nationalen Rennlizenz gemäss den Bestimmungen von Swiss Moto.',
      bulletPoint2:
        'Ich bestätige, dass ich im vollen Besitz meiner geistigen Fähigkeiten bin und keine gesundheitlichen Beeinträchtigungen oder Gebrechen vorliegen, die meine Fähigkeit, sicher als Rennfahrer zu agieren, beeinträchtigen könnten.',
      bulletPoint3:
        'Mir ist bewusst, dass falsche oder unvollständige Angaben meiner geistigen oder körperlichen Gesundheit strafrechtliche und zivilrechtliche sowie auch versicherungsrechtliche Konsequenzen nach sich ziehen können.',
      bulletPoint4:
        'Ich erkläre mich damit einverstanden, mich allen erforderlichen medizinischen Untersuchungen zu unterziehen, die von den zuständigen Behörden oder gemäss den Bestimmungen von Swiss Moto gefordert werden können.',
      bulletPoint5:
        'Ich verpflichte mich, Swiss Moto umgehend über jegliche Veränderungen meines Gesundheitszustands zu informieren, die meine Fahrtauglichkeit bei einem Rennen, Training und weiteren Einsätzen, die in Verbindung mit meiner Lizenz im Zusammenhang stehen, beeinflussen könnten.',
      endParagraph:
        'Diese Eigenerklärung gilt als integraler Bestandteil meines Lizenzantrages und kann von Swiss Moto zu Überprüfungszwecken herangezogen werden. Eine unvollständige oder falsche Eigendeklaration hat in jedem Fall den sofortigen Entzug der Lizenz, wie auch den Ausschluss von Meisterschaften inklusive Streichung von angesammelten Punkten, zur Folge.',
      datePlaceRiderSignature:
        'Datum, Ort, Unterschrift Fahrer ..........................................................................................................',
    },
  },
  licensePhoto: {
    title: 'Profilfoto',
    status: {
      notUploaded: 'Nicht hochgeladen',
      uploaded: 'Hochgeladen',
      verified: 'Bestätigt',
      invalid: 'Beanstandet, bitte nochmal hochladen',
    },
    options: {
      optionAMakeASelfie: 'Option A: Mit dem Telefon Selfie machen (siehe unten)',
      optionBUploadFromComputer: 'Option B: Foto vom Computer hochladen (siehe unten)',
      checkQuality: 'Qualität des Fotos kontrollieren',
    },
    qualityHint:
      'Bitte achte darauf, dass das Foto in guter Qualität fotografiert / hochgeladen ist. Falls dies nicht der Fall ist, musst du es später noch einmal hochladen.',
    uploadPhoto: 'Profilfoto: Fotografieren / hochladen',
    deletePhoto: 'Foto löschen',
    invalidPhoto: 'Die hochgeladene Datei wurde vom Administrator als ungültig befunden',
    uploadAgain: 'Bitte lade die korrigierte Datei nochmal hoch',
    saveLicensePhoto: 'Profilfoto speichern',
  },
  categoryTypeNames: {
    other: 'Sonstige',
    motocross: 'Motocross',
    supercross: 'Supercross',
    mofacup: 'Mofacup',
    mofacross: 'Mofacross',
    pitbikecross: 'Pitbikecross',
    minibike: 'Minibike',
    trial: 'Trial',
    supermoto: 'Supermoto',
    roadRacing: 'Road Racing',
    iceRace: 'Ice-Race',
    snx: 'SNX',
    dragster: 'Dragster',
    vintage: 'Vintage',
    track: 'Track',
    enduro: 'Enduro',
    enduroCrossCountry: 'Enduro / Crosscountry',
    bergrennen: 'Bergrennen',
    eMoto: 'eMoto',
    otherFms: 'Sonstige',
    omc: 'OMC',
  },
  categoryTypeNamesLong: {
    otherFms: 'One Event / Fun Sport alle Disziplinen',
  },
  importStatusOptions: {
    done: 'Abgeglichen',
    open: 'Import offen',
    outdatedPersonalData: 'Persönliche Daten nicht übereinstimmend',
    locked: 'Keine Aktion möglich',
  },
  none: 'Keine',
  parsingStatusOptions: {
    success: 'OK',
    error: 'Fehler',
  },
  sheetStatusOptions: {
    associate: 'Erstellen',
    delete: 'Löschen',
    missingOrUnpaid: 'Info fehlt oder nicht bezahlt',
    unknown: 'Unbekannt / Fehlerhaft',
  },
  messages: {
    recipient: 'Empfänger (Alle, Sparte, Kategorie)',
    type: 'Mailtyp / Template',
    simulate: 'Vorschau (E-Mails werden nicht versendet)',
    location: {
      label: 'Veranstaltungsort',
      hint: 'z.B. Armeno (Piemont I)',
    },
    date: {
      label: 'Datum',
      hint: 'z.B. Samstag, 24. April 2021 - Sonntag, 25. April 2021',
    },
    sportEvent: 'Veranstaltung',
    sendMessages: 'Nachrichten versenden',
    generatingPreview: 'Vorschau wird generiert...',
    generatePreview: 'Vorschau generieren',
    sendingEmail: 'Mails werden versendet...',
    confirmSendEmail: 'Mails wirklich versenden',
    emailPreview: 'E-Mail Vorschau / versendete E-Mails',
    sent: 'Versendet',
    preview: 'Vorschau',
  },
  internalError: 'Es ist ein unerwarteter Fehler aufgetreten. Bitte wende dich an den Support.',
  inscription: {
    group: 'Gruppe',
    title: 'Einschreiben',
    inscriptionType: 'Typ',
    inscriptionTypes: {
      enlistedLicenseInscription: 'Systemvalidierte Anmeldung/Einschreiben für Jahreskategorie',
      enlistedDayInscriptionYearCategoryDraft: 'Unbestätigte Anmeldung für Jahreskategorie / TL',
      enlistedDayInscriptionYearCategory: 'Bestätigtes Einschreiben für Jahreskategorie / TL',
      enlistedDayInscriptionDayCategoryDraft: 'Unbestätigte Anmeldung für Tageskategorie / Clubklasse',
      enlistedDayInscriptionDayCategory: 'Bestätigtes Einschreiben für Tageskategorie / Clubklasse',
      unlistedLicenseInscription: 'Abmeldung Jahreslizenz für Jahreskategorie',
      unlistedDayLicenseInscription: 'Abmeldung Tageslizenz',
    },
    pendingTasksDescription: 'aber es fehlt noch',
    yearLicenseRequired: 'Für diese Kategorie muss eine Jahreslizenz gelöst werden',
    dayLicenseInThisCategoryTemporarilyDisabled:
      'Die Tageslizenzen in dieser Kategorie sind noch nicht verfügbar. Bitte versuche es 2 Wochen vor der Veranstaltung nochmals',
    enlistedOnWaitlist: 'angemeldet, aber noch nicht bestätigt / auf der Warteliste',
    payAndInscribe: 'Kostenpflichtig einschreiben',
    inscribing: 'Einschreiben läuft...',
    inscribeFor: 'Einschreiben für',
    editInscriptionBooking: 'Einschreibebuchung bearbeiten',
    editInscriptionBookingTooltip: 'Einschreibebuchung bearbeiten',
    saveInscriptionBooking: 'Einschreibebuchung speichern',
    deleteInscriptionConfirmation: 'Wirklich löschen?',
    deleteInscriptionTitle: (fullName: string) => `Einschreiben von ${fullName} löschen`,
    deleteInscriptionInfo: (fullName: string, sportEvent: string) =>
      `Einschreiben von ${fullName} für ${sportEvent} löschen. Die Einschreib-Buchungen werden ebenfalls gelöscht, die generierten Rechnungen aber (noch) nicht. Allfällige Bussen müssen manuell eingetragen werden.`,
    deleteInscriptionTooltip: (sportEvent: string) => `Einschreiben für ${sportEvent} löschen`,
    migrateInscriptionBookings:
      'Einschreibbuchungen migrieren. Muss nur 1x gemacht werden, Knopf wird wieder entfernt. Dauert ein paar Sekunden.',
    inscriptionDetails: 'Einschreiben Details',
    inscriptionStatistics: 'Einschreiben Statistik',
    addNewInscription: 'Neues Einschreiben erfassen',
    addInscription: 'Einschreiben hinzufügen',
    editInscription: 'Einschreiben bearbeiten',
    fineForLastInscription: 'Busse erfassen für letztes Einschreiben',
    fineForInscription: (categoryName: string, sportEvent: string) =>
      `Busse für Einschreiben in ${categoryName} / ${sportEvent}`,
    inscriptionCurrentlyDeactivated: 'Diese Funktion ist derzeit deaktiviert (Einschreiben für Admin)',
    categoryNotStartingIn: (category: string, sportEvent: string) =>
      `Kategorie ${category} startet nicht in ${sportEvent}`,
    modifySportsEventInscription: (inscriptionType: string, sportEvent: string) =>
      `${inscriptionType} von ${sportEvent} bestätigen / bearbeiten / ablehnen`,
    confirmManually: 'Manuell bestätigen (alle anderen Prüfungen vom System werden deaktiviert)',
    removeManualConfirmation:
      'Manuelle Bestätigung entfernen (die anderen Prüfungen vom System werden wieder aktiviert)',
    noTransponderRegistered: (transponderType: string) => `Kein ${transponderType} erfasst`,
    noTransponderAssigned: (transponderType: string) => `Kein ${transponderType} zugewiesen`,
    confirmRegistration: 'Anmeldung bestätigen',
    enlistInscriptionInfo: (numberOfRiders: number, numberOfPayments: number, riderLimit: number) =>
      `Es haben sich ${numberOfRiders} Fahrer angemeldet. Davon sind von bereits ${numberOfPayments} Zahlungen eingegangen. Es starten maximal ${riderLimit} Fahrer in dieser Kategorie.`,
    enlistInscriptionOrderInfo:
      'Die Anmeldungen werden primär nach Zahlungseingang priorisiert. Falls zwei Zahlungen gleichzeitig eintreffen, gilt das Anmeldedatum als zweite Priorität. Solange deine Zahlung nicht auf unserem Konto eingegangen ist, kann dein Startplatz an andere Fahrer vergeben werden, die vor dir bezahlen.',
    enlistPaymentInfo:
      'Falls du bezahlst und nicht starten kannst, weil das Feld voll ist, wird dein Guthaben im Racemanager gutgeschrieben. Mit diesem Guthaben kannst du dich für das nächste Rennen anmelden.',
    enlistWaitlistInfo:
      'Falls das Feld bei deinem Zahlungseingang bereits voll ist, kommst du auf die Warteliste. Falls dann spontan ein Platz verfügbar wird, bist du automatisch für das Rennen angemeldet. In diesem Fall kannst du dein Geld nicht mehr zurückfordern.',
    enlistPaidRidersInfo: (numberOfPaidRiders: number, inscriptionLimit: number) =>
      `Es haben bereits ${numberOfPaidRiders} Fahrer bezahlt, aber es können maximal ${inscriptionLimit} Fahrer starten.`,
    enlistOnWaitlistInfo:
      'Deshalb kommt deine Anmeldung auf die Warteliste. Du kannst nur noch hoffen, dass Fahrer, die bereits bezahlt haben, sich wieder abmelden. Ansonsten kannst du nicht starten.',
    enlistRiderLimitInfo: (numberEnlisted: number, inscriptionLimit: number) =>
      `Es sind ${numberEnlisted} Fahrer angemeldet, aber es können maximal ${inscriptionLimit} Fahrer starten.`,
    enlistWaitlistPaymentInfo:
      'Deshalb kommt deine Anmeldung auf die Warteliste. Wenn die anderen Fahrer vor dir oder gleichzeitig wie du bezahlen, wirst du nicht starten können. Wenn du bezahlst, und ein anderer angemeldeter Fahrer nicht, erhältst du seinen Platz.',
    enlistInscriptionOrderRules: (numberOfRiders: number, numberOfPaidRiders: number) =>
      `Es haben sich ${numberOfRiders} Fahrer angemeldet. Davon sind von bereits ${numberOfPaidRiders} Zahlungen eingegangen. Die Startplätze sind limitiert je nach Veranstaltung / Verband / Piste, und lizenzierte Fahrer haben Vorrang gemäss Reglement der jeweiligen Kategorie. Bitte lese diese Regelungen durch, bevor du mit dem entsprechenden Verband Kontakt aufnimmst. Danke.`,
    enlistDayLicenseInfo:
      'Falls es freie Plätze hat, nachdem sich die Fahrer mit einer Lizenz angemeldet haben, werden die zusätzlichen Anmeldungen primär nach Zahlungseingang priorisiert. Falls zwei Zahlungen gleichzeitig eintreffen, gilt das Anmeldedatum als zweite Priorität. Solange deine Zahlung nicht auf unserem Konto eingegangen ist, kann dein Startplatz an andere Fahrer vergeben werden, die vor dir bezahlen.',
    updatingInscriptions: 'Einschreiben werden aktualisiert...',
    moveInscriptionsError: 'Kann nicht (alle) Einschreiben verschieben',
    moveOrDeleteInscriptionNotice: 'Folgende Anmeldungen werden verschoben oder gelöscht',
    categoryId: 'Kategorie ID',
    from: 'Von',
    to: 'Nach',
    collect: 'Einkassieren',
    raceDate: 'Renndatum',
    riderRemarks: 'Bemerkungen Fahrer',
    adminRemarks: 'Bemerkungen Admin',
    unlistedInscriptionCreatedAt: 'Abmeldung am',
    enlistedInscriptionCreatedAt: 'Anmeldung am',
    sidecar: 'Seitenwagen',
    noPartnerAssigned: 'Noch kein Partner zugewiesen',
    sideCarPartnerLabel: 'Seitenwagen Partner (<Nachname> <Vorname>)',
    assignPartner: 'Partner zuweisen',
    openTasks: 'Offene Tasks',
    manualConfirmation: 'Manuelle Bestätigung',
    manuallyVerified: 'Manuell verifiziert',
    verifiedBySystem: 'Vom System verifiziert',
    addFineByAssociation: 'Busse erfassen (Verband)',
    fromRequestedLicense: 'Aus beantragter Lizenz',
    confirmInscription: (issuedNumber: number) => `Anmeldung mit Startnummer ${issuedNumber} bestätigen`,
    bikeMakeLabel: 'Motorradmarke',
    inscriptionDeactivatedFor: 'Einschreiben deaktiviert für',
    unsubscribeNotPossibleAnymoreTitle: (sportEventName: string) =>
      `Abmeldungen nicht mehr möglich für ${sportEventName}`,
    unsubscribeNotPossibleAnymoreText: (sportEventName: string) =>
      `Abmeldungen für ${sportEventName} sind über den Racemanager nicht mehr möglich. Melde dich beim Veranstalter oder dem Sekretariat, wenn du dich abmelden möchtest`,
    filterByStatus: 'Filtern nach Status:',
    filterByType: 'Filtern nach Typ:',
  },
  search: 'Suchen (Name, Kategorie, #7, UID, Verband,',
  chooseCategoryAndDay: 'Kategorie / Tag wählen',
  detailsAndEdit: 'Details / bearbeiten',
  searchRider: 'Fahrer suchen',
  selectedRiderTooltip: 'Dieser Fahrer ist bereits ausgewählt => nach oben scrollen',
  deleteEntry: 'Eingabe löschen',
  searchRiderLabel: 'Suchen (Name, Geburtsdatum, UID, PLZ, Ort, MGNR)',
  myLaps: 'Zeitmessung',
  exportForMyLaps: 'Export für Zeitmessung',
  exportMyLaps: 'Export Zeitmessung',
  exportAlertTooltip:
    'Achtung: wenn eine Kategorie an beiden Tagen startet ist in diesem Export nicht ersichtlich, an welchem Tag die Fahrer starten. Fahrer, die an beiden Tagen starten, erscheinen doppelt in diesem Export.',
  formatByEventAndCategories: 'Format gemäss Veranstaltung und Kategorien',
  assignNoRaceDay: 'Kein Renntag zugewiesen',
  all: 'Alle',
  formatOptionSpecial: 'Format: NACHNAME Vorname, Ort (ohne PLZ)',
  formatOptionNormal: 'Format: Vorname, Nachname, PLZ, Ort',
  personalData: 'Persönliche Daten',
  editPersonalData: 'Zur Person bearbeiten',
  unknown: 'unbekannt',
  qrCodeScanNotAvailable: 'ImageCapture ist in deinem Browser/OS im Moment nicht verfügbar',
  scanning: 'Wird gescannt...',
  scanCancelled: 'QR-Scan abgebrochen',
  scanNotCompleted: 'QR-Code konnte nicht gescannt werden',
  qrScan: 'QR-Scan',
  qrScanLong: 'Lizenz oder Boxenkarte scannen',
  tryAgain: 'Nochmal versuchen',
  showOnlyCategoriesWithLicense: 'Nur Kategorien mit meinen Lizenzen / Anmeldungen anzeigen',
  startList: 'Startliste',
  startLists: 'Startlisten',
  updatePublicStartLists: 'Öffentliche Startliste aktualisieren / abgleichen',
  for: 'für',
  dayLicense: 'Tageslizenz',
  issue: 'ausstellen',
  deleted: 'gelöscht',
  unpaid: 'unbez',
  incomplete: 'unvollständig',
  twoDayDiscount: '2-Tages-Rabatt',
  dayUnconfirmedDate: 'gemäss Tagesprogramm',
  inscriptionStatusLong: {
    'not-inscribed': 'Nicht angemeldet',
    unlisted: 'Abgemeldet',
    inscribed: 'Eingeschrieben',
    'inscription-pending': 'Angemeldet',
  },
  inscriptionTypeSearch: {
    dayLicense: 'TL',
    dayCategory: 'Club',
    license: 'Lizenz',
    unlisting: 'Abmeldung',
  },
  inscriptionStatusShort: {
    'not-inscribed': 'Nicht angem.',
    unlisted: 'Abgemeldet',
    inscribed: 'Eingeschrieben',
    'inscription-pending': 'Unvollst.',
  },
  kpiTitles: {
    totalBalances:
      'Total aller Fahrer, ohne eingegangene QR-Zahlungen (positiv = Schulden der Fahrer an Verband)',
    totalBalanceWithouLicenseBookings:
      'Total aller Fahrer, ohne eingegangene QR-Zahlungen (positiv = Schulden der Fahrer an Verband), abzüglich offene Lizenzen',
    totalAutomaticPayments: 'Total automatisch erfasste Zahlungen',
    totalManualPayments: 'Total manuell erfasste Zahlungen',
    totalInscriptionBookings: 'Total Einschreiben',
    openInscriptionBookings: 'Offene Einschreiben',
    paidInscriptionBookings: 'Bezahlte Einschreiben',
    totalLicenseBookings: 'Total Lizenzen',
    openLicenseBookings: 'Offene Lizenzen',
    paidLicenseBookings: 'Bezahlte Lizenzen',
    totalManualBookings: 'Total Manuelle Buchungen',
    totalTransponderBookings: 'Lizenzbuchungen: Total Transponder',
    openTransponderBookings: 'Lizenzbuchungen: Offene Transponder',
    paidTransponderBookings: 'Lizenzbuchungen: Bezahlte Transponder',
    totalCategoryBookings: 'Lizenzbuchungen: Total Lizenzgebühren',
    openCategoryBookings: 'Lizenzbuchungen: Offene Lizenzgebühren',
    paidCategoryBookings: 'Lizenzbuchungen: Bezahlte Lizenzgebühren',
    totalCategoryBookingsWithInsurance: 'Lizenzbuchungen Volllizenz: Total Lizenzgebühren',
    openCategoryBookingsWithInsurance: 'Lizenzbuchungen Volllizenz: Offene Lizenzgebühren',
    paidCategoryBookingsWithInsurance: 'Lizenzbuchungen Volllizenz: Bezahlte Lizenzgebühren',
    totalAmountCategoryBookingsWithInsurance:
      'Lizenzbuchungen Volllizenz: Anzahl Total Lizenzen (nicht CHF)',
    openAmountCategoryBookingsWithInsurance:
      'Lizenzbuchungen Volllizenz: Anzahl Offene Lizenzen (nicht CHF)',
    paidAmountCategoryBookingsWithInsurance:
      'Lizenzbuchungen Volllizenz: Anzahl Bezahlte Lizenzen (nicht CHF)',
    totalInsuranceCategoryBookingsWithInsurance:
      'Lizenzbuchungen Volllizenz: Total Versicherungsbeiträge',
    openInsuranceCategoryBookingsWithInsurance:
      'Lizenzbuchungen Volllizenz: Offene Versicherungsbeiträge',
    paidInsuranceCategoryBookingsWithInsurance:
      'Lizenzbuchungen Volllizenz: Bezahlte Versicherungsbeiträge',
    totalAmountCategoryBookingsSecondary:
      'Lizenzbuchungen ohne Versicherungsanteil: Anzahl Total Lizenzen (nicht CHF)',
    openAmountCategoryBookingsSecondary:
      'Lizenzbuchungen ohne Versicherungsanteil: Anzahl Offene Lizenzen (nicht CHF)',
    paidAmountCategoryBookingsSecondary:
      'Lizenzbuchungen ohne Versicherungsanteil: Anzahl Bezahlte Lizenzen (nicht CHF)',
    licenseBookingKPIs: 'Lizenzbuchungen KPIs',
    licenceBookingsByCategoryType: 'Lizenzbuchungen nach Sparte',
  },
  accountOverview: 'Übersicht',
  financials: {
    financials: 'Finanzielles',
    financialsFor: 'Finanzielles zu',
    amountReceived: 'Betrag erhalten',
    amountPaid: 'Betrag ausbezahlt',
    debit: 'Belastung',
    credit: 'Gutschrift',
    open: 'Offen',
    paidOn: 'Bezahlt am',
    payment: 'Zahlung',
    overpayment: 'Überbezahlung',
    underpayment: 'Teilzahlung',
    replaced: 'Ersetzt durch neuere Rechnung',
    inscriptionDiscount: 'Rabatt gutgeschrieben',
    priceRangeTextBetween: (from: string, to: string) => `Geburtsdatum zwischen ${from} und ${to}:`,
    priceRangeTextOlderThan: (from: string) => `Geburtsdatum älter als ${from}:`,
    priceRangeTextYoungerThan: (to: string) => `Geburtsdatum jünger als ${to}:`,
    manualBookingsAndCharges: 'Manuelle Gutschriften & Belastungen',
    newManualBookingTitle: 'Neue manuelle Buchung',
    newManualBooking: 'Neue manuelle Buchung',
    saveNewManualBooking: (bookingKind: string) => `${bookingKind} speichern`,
    deleteManualBooking: (bookingItemName: string) => `Manuelle Buchung ${bookingItemName} löschen`,
    editManualBookingButton: 'Manuelle Buchung bearbeiten',
    editManualBooking: (bookingKind: string) => `Manuelle ${bookingKind} bearbeiten`,
    saveManualBooking: (bookingKind: string) => `${bookingKind} speichern`,
    copyBooking: 'Buchung kopieren',
    bookingsOnRiderChanged:
      'Achtung: wenn der Fahrer geändert wird, wird eine neue Buchung erstellt, und die alte Buchung muss manuell gelöscht werden.',
    riderChanged: 'Fahrer geändert',
    generateNewBill: 'Neue Rechnung generieren',
    eventFilter: 'Filter Veranstaltungen einblenden / ausblenden',
    cashOnEvent: 'Barzahlungen um die Veranstaltungsdaten',
    selectEvent: 'Bitte wähle eine Veranstaltung',
    infoExternalEvent: 'Finanzen einer fremden Veranstaltung werden nicht angezeigt',
    byCategoryType: 'Nach Sparte',
    byCategory: 'Nach Kategorie',
    byInscription: 'Nach Einschreiben',
    accountBalances: 'Kontostände',
    accountBalancesPerRider: 'Kontostände pro Fahrer',
    totalForRider: 'Total (aus Sicht des Fahrers: positiv: Guthaben, negativ: Offene Posten)',
    filter: 'Filter',
    riderHasCredit: 'Fahrer hat Guthaben',
    riderOwsMoney: 'Fahrer schuldet Geld',
    balancingAccounts: 'Ausgeglichene Konten',
    licenseBookingsPerRider: 'Lizenzbuchungen nach Fahrer',
    necessaryBookings: 'Nötige Buchungen',
    createdBookings: 'Erstellte Buchungen',
    recalculatePaymentStatus: 'Zahlungsstatus neu berechnen',
    deleteLicenseBooking: (bookingName: string) => `Lizenzbuchung ${bookingName} löschen`,
    totalCreditOrganization: 'Total (Guthaben Organisation)',
    totalOpenPayments: 'Offen (ausstehende Zahlungen)',
    overwritePaymentDate: (date: string) => `Auszahlungsdatum wird überschriben mit ${date}`,
    paymentDateAlert:
      'Auszahlungsdatum der Buchungen wird nicht geändert, Buchungsdatum im XML wird auf den nächsten Geschäftstag gesetzt',
    summarizedPositionsForBilling: 'Zusammengefasste Positionen für die Abrechnung',
    positionsForBilling: 'Positionen für die Abrechnung',
    recalculatePaymentStatusAlert:
      'Zahlungsstatus neu berechnen, falls der Zahlungsstatus mit dem Kontostand nicht übereinstimmt, wird in Zukunft automatisch berechnet. Dauert ein paar Sekunden.',
    addFineButtonText: 'Busse erfassen',
    addFineTitle: 'Busse erfassen',
    fine: 'Busse',
    bookingsOtherAssociations: 'Buchungen anderer Verbände',
    deleteBill: (transactionTitle: string) => `Rechnung ${transactionTitle} löschen`,
    reopenBill: 'Rechnung wieder öffnen',
    deleteBooking: (transactionName: string) => `Buchung ${transactionName} löschen`,
    removeQrPayment: (transactionReference: string) =>
      `QR-Zahlung ${transactionReference} vom Benutzer entfernen`,
    deleteQrPayment: (transactionReference: string) => `QR-Zahlung ${transactionReference} löschen`,
    qrPaymentNotice: 'QR-Zahlung wurde manuell zugewiesen => Zuweisung rückgängig machen',
    deletePaymentNotice:
      'Die Zahlung wird komplett gelöscht, als ob der Import nicht stattgefunden hätte',
    deleteAssignment: 'Zuweisung löschen / rückgängig machen',
    deletePayment: (paymentTitle: string) => `${paymentTitle} löschen`,
    desiredDate: 'Gewünschtes Datum',
    requestedByUid: 'Angefordert von UID',
    paidByUid: 'Bezahlt von UID',
  },
  accountingGroups: {
    sumNames: {
      open: 'Offen',
      inscription: 'Startgelder',
      donation: 'Spenden',
      yearLicense: 'Jahreslizenzeinnahmen',
      dayLicense: 'Tageslizenzeinnahmen',
      transponder: 'Verkaufte Transponder',
      automaticPayment: 'Automatische Zahlungen',
      manualBooking: 'Manuelle Buchungen',
      manualPayment: 'Manuelle Zahlungen',
      associationPayment: 'Auszahlungen an Verband',
    },
  },
  multipleEvents: 'mehreren Veranstaltungen',
  showOldTransactions: 'Alte Transaktionen anzeigen',
  overviewBox: {
    personalDataCompleted: 'Persönliche Daten ausgefüllt',
    requestLicense: (year: number) => `Lizenzgesuch ${year} ausfüllen`,
    licenseRequestCompleted: 'Lizenzgesuch ausgefüllt',
    payMemberFees:
      'SAM Mitgliederrechnung des entsprechenden Jahres bezahlen (du hast dazu eine Rechnung per Post erhalten). Die Einzahlung per Rechnung dauert einige Tage, bis sie deinem Konto gutgeschrieben wird.',
    samMembershipPaid: 'SAM Mitgliederrechnung bezahlt',
    payBills:
      'Offene Rechnungen begleichen (siehe unten, unter "Finanzielles"). Die Einzahlung per Rechnung dauert einige Tage, bis sie deinem Konto gutgeschrieben wird.',
    noOpenLicenseFees: 'Keine offene Lizenzrechnung',
    addHealthCheck: 'Gesundheits-Check erfassen',
    healthCheckCompleted: 'Gesundheits-Check abgeschlossen',
    addInsuranceConfirmation: 'Versicherungsbestätigung erfassen',
    insuranceConfirmationUploaded: 'Versicherungsbestätigung hochgeladen',
    uploadLicensePhoto: 'Profilfoto erfassen',
    licensePhotoUploaded: 'Profilfoto hochgeladen',
    bikeAdded: 'Motorrad hinzugefügt',
    adminConfirmationPending: 'Noch nicht vom Administrator bestätigt / auf der Warteliste',
    adminConfirmationDone: 'Vom Administrator bestätigt',
    inscriptionSubmitted: 'Anmeldung abgeschickt',
    inscriptionNotSubmitted: 'Anmeldung noch nicht abgeschickt',
    inscriptionPaid: 'Anmeldung bezahlt',
  },
  inscriptionBox: {
    tasksNotDone: 'Aufgaben noch nicht erfüllt (siehe Übersicht)',
    licenseNotApproved: 'Lizenz noch nicht bestätigt',
    showAllCategories: 'Alle Kategorien und Sparten anzeigen',
    showAllCategoriesPerSportEvent: 'Tageslizenz lösen / Clubklasse einschreiben',
    hideAllCategoriesPerSportEvent: 'Tageslizenzen / Clubklassen verstecken',
    showInscriptions: 'Einschreiben anzeigen',
    search: 'Suchen (Veranstaltung, Verband, Kategorie, ...)',
    inscriptionPending:
      '* PROVISORISCH ANGEMELDET: bitte beim Einschreiben melden: noch nicht bestätigt, oder Bedingungen noch nicht erfüllt (z.B. Zahlung noch nicht eingegangen)',
  },
  otherSummary: 'Sonstiges',
  licensesBoxes: {
    invalidatedLicense: {
      title: 'Ungültige Lizenz',
      explanation:
        'Die Lizenz wurde vom Verband für ungültig erklärt. Bei Fragen kontaktiere den Verband.',
    },
    showLicensesBefore: (year: number) => `Lizenzen vor ${year} anzeigen`,
    hideLicensesBefore: (year: number) => `Lizenzen vor ${year} verstecken`,
    approvedLicensesTitle: 'Bestätigte Lizenzgesuche',
    draftLicensesTitle: 'Beantragte Lizenzen',
    preferredNumber: 'Gewünschte Startnummer',
    definitiveIssuedNumber: 'Definitive Startnummer',
    provisionallyIssuedNumber: 'Provisorische Startnummer',
    sidecarPartner: 'Seitenwagenpartner',
    unknownCategory: 'Unbekannte Kategorie',
    requestNewLicense: (licenseYear: number) => `Lizenz(en) für ${licenseYear} beantragen`,
    requestAdditionalLicense: (licenseYear: number) =>
      `Weitere Lizenz(en) für ${licenseYear} beantragen`,
    requestLicenseOfDifferentYear: (licenseYear: number) => `Zum Lizenzgesuch ${licenseYear}`,
    downloadLicense: 'Lizenz herunterladen',
    licenseConfirmation: 'Lizenzbestätigung',
    download: {
      license: 'Lizenz herunterladen',
      pitLanePass: 'Boxenkarte herunterladen',
    },
    emergencyDetails: 'Notfallblätter',
    myLaps: 'Zeitmessung',
    organizerList: 'Veranstalterliste',
    search: 'Suchen (Name, Kategorie, #7, Mitgliedernummer, Bestätigt/Neu,',
    setSearchInfo: 'Zuweisung läuft, kann einige Minuten dauern...',
    assignmentInProgress: 'Zuweisung läuft...',
    assignLicenseId: 'Lizenz IDs zuweisen',
    importLicenses: 'Lizenzen importieren',
    licensesToImport: 'Zu importierende Lizenzen',
    notFound: 'Nicht gefunden:',
    overwrite: 'Überschreiben',
  },
  personalDataBox: {
    title: 'Zur Person',
    samMembershipNumber: 'SAM Mitgliednummer',
    createPersonalData: 'Daten zur Person erfassen',
    changeEmail: {
      title: 'E-Mail ändern',
      tryAgain: 'Nochmal versuchen',
      saving: 'Wird gespeichert...',
      startEmailChangeTitle: 'Bestätigungs-Link versendet',
      startEmailChangeBefore: 'Wir haben ein E-Mail an',
      startEmailChangeAfter: 'gesendet. Bitte bestätige dieses Mail, um die E-Mail-Änderung zu starten.',
      emailChangedTitle: 'E-Mail ändern Link versendet',
      emailChangedBefore: 'Wir haben ein E-Mail an',
      emailChangedAfter: 'gesendet. Bitte bestätige die E-Mail, die Änderung abzuschliessen.',
      securityHint: 'Aus Sicherheitsgründen musst du die Aktion bestätigen, um die E-Mail zu ändern.',
      confirmationEmailSent: 'Bestätigungs-Link versendet',
      confirmWithGoogle: 'Bestätigen mit Google',
      confirmWithFacebook: 'Bestätigen mit Facebook',
    },
  },
  documentBox: {
    title: 'Dokumente',
    licensePhoto: 'Profilfoto',
    contactOnAccident: 'Notfallblatt: Bei Unfall benachrichtigen',
    emergencyDataMissing: 'Notfallblatt: noch nicht ausgefüllt',
    healthCheck: 'Gesundheits-Check',
    insuranceConfirmation: 'Versicherungsbestätigung',
    driversLicense: 'Führerausweis',
  },
  driversLicense: {
    driversLicenseId: 'Führerausweisnummer',
  },
  optionalMessage: {
    title: 'Optionale Angaben',
    messagePart1: 'Anhand deiner Lizenz(en) sind diese Angaben',
    messagePart2Highlighted: 'optional',
    messagePart3:
      '. Du darfst die Angaben aber trotzdem hier erfassen, falls du an einer Veranstaltung teilnehmen möchtest, wo diese Angaben erforderlich sind',
  },
  transactions: {
    name: 'Transaktionen (Gutschriften, Belastungenen, Zahlungen, Lizenzbuchungen, Einschreibbuchungen)',
    transactionsByType: 'Transaktionen nach Buchungstyp',
    downloadBill: 'Rechnung herunterladen',
    license: 'Lizenzgesuch',
    inscription: 'Einschreiben',
    inscriptionDayLicense: 'Einschreiben (Tageslizenz)',
    inscriptionDayCategory: 'Einschreiben (Einzelveranstaltung)',
    power: 'Strom',
    donation: 'Spende',
    inscriptionDiscount: '2-Tages-Rabatt',
    mainLicenseName: 'Lizenz',
    additionalLicenseName: 'Zusatzlizenz',
    discountName: 'Rabatt bis zum',
    surchargeName: 'Zuschlag ab dem',
    reverse: 'Storniert:',
  },
  emergencyDetails: {
    title: 'Notfallblatt',
    titlePlural: 'Notfallblätter',
    editEmergency: 'Notfallblatt bearbeiten',
    addEmergencyDetails: 'Notfallblatt erfassen',
    checkEmergencyDetails: 'Notfallblatt überprüfen',
    emergencyDetailsChecked: 'Notfallblatt geprüft',
    emergencyDetailsCompleted: 'Notfallblatt erfasst',
    emergencyConfirmation: {
      label:
        'Ich bestätige, dass ich ausreichend bei einem Unfall versichert bin und das Reglement meiner Kategorie gelesen habe und anerkenne. Ich verzichte ausdrücklich darauf, im Falle eines Unfalles und/oder Schadenfalles den organisierenden Verband/Verbände beziehungsweise seine Kommissionen und Funktionäre, den Veranstalter sowie gegenüber anderen Fahrern bzw. deren Betreuer oder Angehörige, für allfällige Ansprüche haftbar zu machen. Ich nehme zur Kenntnis, dass es mir selber obliegt, mich für alle möglichen Schadenfälle privat abzusichern (versichern) oder das Risiko vollumfänglich selber zu tragen habe.',
      validation: 'Die Bedingungen müssen akzeptiert werden, um Rennen zu fahren',
    },
    seeEmergencyDetails: 'Notfallblatt einsehen',
    dataProtectionHint: 'Aus Datenschutzgründen wird dieser Zugriff aufgezeichnet. Wirklich öffnen?',
  },
  bikesBox: {
    frameNumber: 'Rahmennummer',
    bikeMissing:
      'Noch kein Motorrad erfasst. Für ein Rennen musst du dein Motorrad hier erfassen. Derzeit können nur Motorräder für Motocross und Supermoto erfasst werden - die anderen Sparten folgen.',
    deleteBike: 'Motorrad löschen',
    addNewBike: 'Neues Motorrad erfassen',
  },
  delete: 'Löschen',
  assign: 'zuweisen',
  newMember: 'Neues Mitglied',
  previousLicenses: 'Vorjahreslizenzen',
  buttons: {
    edit: 'Bearbeiten',
    add: 'Erfassen',
    editEmail: 'E-Mail ändern',
    cancel: 'Abbrechen',
    close: 'Schliessen',
    back: 'Zurück',
    continue: 'Weiter',
    save: 'Speichern',
    saving: 'Wird gespeichert...',
    saveLicenseBooking: 'Lizenzbuchung speichern',
    changeEmail: 'E-Mail Ändern',
    addObject: (object: string) => `${object} erfassen`,
    addObjectOptional: (object: string) => `${object} erfassen (optional)`,
    editObject: (object: string) => `${object} bearbeiten`,
    editObjectOptional: (object: string) => `${object} bearbeiten (optional)`,
    deleteButtonConfirmation: 'Wirklich löschen?',
  },
  alerts: {
    dataSaved: 'Daten gespeichert',
    errorSaving: 'Fehler beim speichern',
    unknownError: 'unbekannter Fehler',
  },
  after: 'nach',
  licenseForm: {
    tooEarly: {
      title: 'Du bist zu früh',
      message:
        'Das Lizenzgesuch ist noch nicht aufgeschaltet. Bitte versuche es später noch einmal. Folge uns auf Social Media, um über die Aufschaltung informiert zu werden.',
    },
    mxrsSamFusionInfoP1:
      'Die MXRS und der SAM-Sport haben Ende 2022 fusioniert. Für die Saison 2023 wird eine neue Meisterschaft National Amateur Motocross Serie (NAM) lanciert, wo die MXRS und SAM Kategorien in neuen Kategorien zusammengeführt werden.',
    mxrsSamFusionInfoP2:
      'Falls du noch nicht in einer SAM Sektion Mitglied bist, kannst du dich einer bestehenden Sektion anschliessen, oder dich der neuen Sektion MXRS anmelden (das folgt in einem nächsten Schritt im Lizenzgesuch).',
    mxrsSamFusionInfoP3:
      'Die Organisatoren im Hintergrund bleiben gleich. Weiter bist du beim lösen einer SAM Lizenz zusätzlich versichert - es kann also sein, dass du deine bestehende Zusatzversicherung nicht mehr brauchst.',
    mxrsSamFusionInfoP4:
      'Wir freuen uns auf eine erfolgreiche Saison 2023, zusammen mit den Organisatoren der ehemaligen MXRS und der neuen NAM Serie des SAM.',
    mxrsSamFusionInfoP5: 'Löse dazu eine SAM Lizenz.',
    requestTransponders: 'Transponder kaufen',
    requestLicenses: 'Lizenzen beantragen',
    requestLicense: 'Lizenz beantragen',
    total: 'Total',
    insuranceTerms: 'Details zur Rennunfallversicherung',
    searchCategories: 'Kategorie suchen (z.B. MX2, "Youngster 85", Vintage 50+, ...)',
    searchCategoriesMin3Letters:
      'Kategorie suchen, min. 3 Buchstaben (z.B. SAM, MX2, "Youngster 85", Vintage 50+, ...)',
    typeAtLeast3Letters: 'Mindestens 3 Buchstaben tippen, um weitere Kategorien hinzuzufügen',
    advertiseSamInsurance: 'Versicherungsschutz via SAM',
    title: 'Lizenzgesuch',
    price: 'Preis',
    steps: {
      personalData: {
        title: 'Zur Person',
        samSectionChange: 'SAM Sektionswechsel',
      },
      lastSeason: 'Letzte Saison',
      categories: {
        title: 'Kategorien',
        includingInsurance: 'Inklusive erweiterter Versicherungsdeckung',
      },
      transponder: 'Transponder',
      additionalInfo: 'Weitere Informationen',
      summary: {
        title: 'Zusammenfassung',
        noBirthdateFound: 'Geburtsdatum nicht eingetragen, bitte ergänze deine persönliche Daten',
        openCreditsAndDebits:
          'Allfällige Guthaben und ausstehende Bussen sind noch nicht berücksichtigt.',
        category: 'Kategorie',
        preferredNumber: 'Gewünschte Startnummer',
        priceChf: 'Preis CHF',
        conclusion: 'Abschluss',
        alertTitleLicenseYear: (previousYear: number) => `Achtung: Lizenzgesuch Saison ${previousYear}`,
        alertBodyLicenseYear: (nextYear: number, previousYear: number) =>
          `Die Lizenzen für die Saison ${nextYear} sind noch nicht aufgeschaltet. Dieses Lizenzgesuch hier gilt noch für die Saison ${previousYear}.`,
      },
      bikes: {
        title: 'Motorrad für Motocross/Supermoto/Minibike erfassen',
        alertTitleOtherCategoryTypes:
          'Andere Sparten ausser Motocross, Supermoto und Minibike werden später hinzugefügt, mit spezifischen Feldern der entsprechenden Sparte',
        alertBodyOtherCategoryTypes:
          'Dieses Formular ist derzeit nur für die Sparten Motocross, Supermoto und Minibike verfügbar, da die ersten Rennen in diesen Sparten geplant sind. Das Formular für andere Sparten wird noch vor dem jeweiligen ersten Rennen aufgeschaltet.',
        bikeAdded: 'Motorrad hinzugefügt',
        bikeNotSaved: 'Motorrad konnte nicht gespeichert werden',
        saveBike: 'Motorrad speichern',
      },
    },
  },
  licenseRequest: {
    title: 'licenseRequest',
    licenseRequestSent: 'Lizenzgesuch abgesendet',
    licenseRequestSentHint:
      'Wir haben dein Lizenzgesuch erhalten und werden es bearbeiten. Die Lizenz kannst du hier bezahlen, sobald wir die Prüfung abgeschlossen haben und die Bezahlung hier aufgeschaltet ist. Du erhältst von uns ein E-Mail als Erinnerung.',
  },
  emails: {
    greeting: 'Hallo',
    bestWishes: 'Liebe Grüsse',
    signature: 'Dein Racemanager Team',
    licenseRequestEmail: {
      title: 'Lizenzantrag erhalten',
      text: 'Vielen Dank für deinen Lizenzantrag. Wir werden den Antrag bearbeiten und sobald er akzeptiert wurde, wird dein Gesuch bestätigt und die entsprechende Rechnung erstellt. Schau bitte ab und zu in dein Konto um den aktuellen Stand deiner Lizenz zu überprüfen.',
    },
    paymentReadyEmail: {
      oneLicenseConfirmedTitle: 'Wir haben dein Lizenzgesuch genehmigt und deine Startnummer bestätigt',
      severalLicensesConfirmedTitle:
        'Wir haben folgende Lizenzgesuche genehmigt und deine Startnummern bestätigt',
      oneLicenseConfirmedNextFewDaysBefore: 'Deine',
      oneLicenseConfirmedNextFewDaysMiddle: 'noch unbestätigte',
      oneLicenseConfirmedNextFewDaysAfter:
        'Lizenz wird voraussichtlich in den nächsten Tagen bestätigt.',
      multipleLicensesConfirmedNextFewDaysBefore: 'Deine',
      multipleLicensesConfirmedNextFewDaysMiddle: 'noch unbestätigte',
      multipleLicensesConfirmedNextFewDaysAfter:
        'Lizenzen werden voraussichtlich in den nächsten Tagen bestätigt.',
      insuranceInformation:
        'Als Beginn Versicherungsdeckung wird das Zahlungsdatum festgelegt. Ab da dauert der Versicherungsschutz 1 Jahr, längstens aber bis Ende Februar des Folgejahres. Weitere Informationen:',
      payLicenseBefore:
        'Damit du deine Lizenz erhältst, musst du sie bezahlen. Das kannst du wieder auf der Plattform',
      payLicenseAfter: 'erledigen.',
      numberInformation:
        'In der Saison 2022 müssen die Startnummern an den Motorrädern dem Racemanager Reglement entsprechen. Bei Backyard Racing kannst du direkt dein reglementskonformes Dekor bestellen. Für mehr Informationen und die Bestellung deines Dekors klicke hier:',
      checkCurrentStatus:
        'Den aktuellen Stand deiner Daten und den Status deines Lizenzgesuches kannst du jederzeit auf der Plattform abrufen bzw. bearbeiten.',
      fillInEmergencyDetails:
        'Als nächsten Schritt kannst du das Notfallblatt ausfüllen. Du findest dies ebenfalls auf dem Dashboard der Plattform.',
      number: 'Startnummer',
      paymentLicenseRequestDueEndSingular: 'Zahlung Lizenzgesuch fällig',
      paymentLicenseRequestDueDateEndPlural: 'Zahlung Lizenzgesuche fällig',
    },
  },
  licenseTasks: {
    names: {
      personalData: 'Persönliche Daten nicht erfasst',
      licenseSubmitted: 'Lizenzgesuch nicht abgesendet',
      memberFees: 'SAM Mitgliederrechnung offen',
      licenseFees: 'Lizenzrechnung offen',
      checkEmergency: 'Notfallblatt nicht geprüft',
      emergency: 'Notfallblatt nicht erfasst',
      healthCheck: 'Gesundheits-Check nicht erfasst',
      insurance: 'Versicherungsbestätigung nicht erfasst',
      photo: 'Profilfoto nicht hochgeladen',
      inscriptionPayment: 'Einschreibgebühr nicht bezahlt',
      adminConfirmationPending:
        'Noch nicht vom Admin/Veranstalter bestätigt, und möglicherweise auf der Warteliste',
      bike: 'Kein Motorrad erfasst',
      inscriptionSubmitted: 'Anmeldung nicht abgeschickt',
    },
    shortNames: {
      personalData: 'Pers.',
      licenseSubmitted: 'Lizenzges.',
      memberFees: 'Mitgliederb.',
      licenseFees: 'Lizenzgeb.',
      emergency: 'Notf.',
      checkEmergency: 'NotfPrüf.',
      healthCheck: 'Gesundh.',
      insurance: 'Versi.',
      photo: 'Foto',
      inscriptionPayment: 'Unbez.',
      adminConfirmationPending: 'AdmBest.',
      bike: 'Moto.',
      inscriptionSubmitted: 'Anm.',
    },
    pdfTasks: {
      emergency: 'Notfallblatt fehlt',
      checkEmergency: 'Notfallblatt nicht geprüft',
      healthCheck: 'Gesundheits-Check fehlt',
      insurance: 'Versicherungsbestätigung fehlt',
      licenseFees: 'Lizenz nicht bezahlt',
      licenseSubmitted: 'Lizenzgesuch nicht abgeschickt',
      personalData: 'Persönliche Daten nicht erfasst',
      memberFees: 'SAM Mitgliederrechnung nicht bezahlt',
      photo: 'Profilfoto fehlt',
      inscriptionPayment: 'Einschreibgebühr nicht bezahlt',
      adminConfirmationPending:
        'Noch nicht vom Admin/Veranstalter bestätigt, und möglicherweise auf der Warteliste',
      bike: 'Motorrad fehlt',
      inscriptionSubmitted: 'Anmeldung nicht abgeschickt',
    },
  },
  licensePdf: {
    valid: 'Gültig',
    invalid: 'Ungültig',
    validUntil: 'Gültig bis',
    notValidYet: 'Noch nicht gültig',
    license: 'Lizenz',
    togetherWithIdOrPassport: '(zusammen mit Ausweis oder Pass)',
    pitLane: 'Pit Lane',
    pitLaneDownload: 'Pit Lane Pass',
    notValidForEntry: "Gilt nicht als Eintritt. N'est pas une entrée valid. Not valid for entry.",
    rider: 'Fahrer',
    transponder: 'Transponder',
    trainingOnly: 'Gültig: nur für Trainings',
    validNationalOnly: 'Gültig: national',
    validInternational: 'Gültig: national / international',
    samDisclaimer:
      "MEDICAL INSURANCE\nThe holder of a SAM rider's licence is insured with respect to medical treatment according to the SAM rules.",
    fmsInternationalDisclaimerTitle: 'STARTING PERMISSION / AUTORISATION DE DÉPART',
    fmsInternationalDisclaimer:
      "The holder of a FIM rider/passenger's licence for International Meetings is granted STARTING PERMISSION for the disciplines mentioned on the front of the licence at all events approved by a member federation of the FIM/FIM Europe. He/she is insured with respect to medical treatment and repatriation according to the FIM/FIM Europe/Swiss Moto rules.\nThis STARTING PERMISSION is not valid for FIM-/FIM Europe-championships and - prizes.\nThe licence is valid only with signature of the rider/passenger",
    scanLicense: 'Lizenz scannen',
  },
  dayInscription: {
    approved: 'eingeschrieben und bestätigt',
  },
  description: 'Beschreibung',
  amountInChf: 'Betrag in CHF',
  sportsEvent: {
    eventOrCategoryNotFound: 'Veranstaltung oder Kategorie nicht gefunden',
    editSportEvent: (sportEvent: string) => `${sportEvent} bearbeiten`,
    sportEventsAdminForm: {
      categoryGroups: 'Anzahl Gruppen (1 falls leer) / Max. Fahrer pro Gruppe (optional)',
      categoryGroupCounts: 'Anzahl Gruppen',
      categoryGroupSizes: 'Fahrer pro Gruppe',
    },
    errorsOccured: 'Folgende Fehler sind aufgetreten',
    newEvent: 'Neue Veranstaltung',
    removeCategoryWarning:
      'Achtung: bestehende Einschreiben werden automatisch gelöscht, wenn eine Kategorie entfernt wird.',
    changeCategoryDateWarning:
      'Achtung: bei gewissen Konstellationen hat nur der Superadmin die Berechtigunen, gewisse Buchungen zu bearbeiten. In dem Fall darf nur der Superadmin das Datum der Kategorien ändern.',
    inscriptionWithoutDateNotice:
      'Wenn das Datum noch nicht bekannt ist, kann trotzdem bereits eingeschrieben werden.',
    changeEventDateNotice:
      'Das Datum der Anmeldungen/Einschreiben wird automatisch geändert, wenn das Datum der Veranstaltung oder der Kategorie geändert wird',
    referToDeletedOrMovedInscriptionTable:
      'Siehe Tabelle "Folgende Anmeldungen werden verschoben oder gelöscht", welche erscheint, sobald eine entsprechende Änderung vorliegt',
  },
  field: 'Feld',
  import: 'Import',
  system: 'System',
  action: 'Aktion',
  documents: 'Dokumente',
  payments: {
    title: 'Zahlungen',
    addManualPaymentTitle: 'Manuelle Zahlung erfassen',
    refund: 'Rückzahlung',
    addNewManualPayment: (paymentName: string) => `Neue manuelle ${paymentName} erfassen`,
    addManualPayment: (paymentName: string) => `${paymentName} erfassen`,
    editManualPaymentTitle: 'Manuelle Zahlung bearbeiten',
    editManualPayment: (paymentName: string) => `Manuelle ${paymentName} bearbeiten`,
    saveManualPayment: (paymentName: string) => `${paymentName} speichern`,
    renameTag: (tag: string) => `Tag ${tag} umbenennen`,
    renameTagTo: (tag: string, newTag: string) => `Tag ${tag} zu ${newTag} umbenennen`,
    renameTagWarning: (tag: string, association: string) =>
      `Achtung: alle Zahlungen aller Jahre mit dem Tag ${tag} des Verbandes ${association} werden umbenannt.`,
    sum: 'Summe',
    invalidPayments: 'Invalide Zahlungen',
    noInvalidPaymentsFound: 'Keine invaliden Zahlungen gefunden',
    deleteQrPayment: 'QR-Zahlung löschen',
    invalidPayment: 'Invalide Zahlung',
    searchPayments: 'Suchen (Name, Mitgliedernummer, PLZ, Ort, MG-Bezahlt/MG-Offen/QR-Bezahlt/QR-Offen)',
    bankPaymentUpload: 'Bankzahlungen Upload',
  },
  bills: {
    openBills: 'Offene Rechnungen',
    searchBillsLabel:
      'Suchen (""amount"":80, / country"":""DE"" / Ausland / Teile Referenznummer / Name / Strasse / PLZ / Ort)',
    invalidEmptyZipCode: 'Ungültige leere Postleitzahl',
    invalidSwissZipCode: 'Ungültige Schweizer Postleitzahl',
    invalidTooLongZip: 'Ungültige zu lange Postleitzahl',
  },
  admins: {
    title: 'Administratoren',
    readOnlyAdmins: 'Administratoren (Lesezugriff)',
    adminEmail: 'E-Mail (muss registriert sein)',
    addAdmin: 'Administrator hinzufügen',
    addAsAdmin: 'Als Administrator hinzufügen',
    confirmAddAdmin: (adminEmail: string) => `${adminEmail} als Administrator hinzufügen?`,
    removeAdmin: 'Administrator entfernen',
    removeAsAdmin: 'Als Administrator entfernen',
    confirmDeleteAdmin: (adminEmail: string) => `${adminEmail} als Administrator entfernen?`,
    associationAdmins: 'Verbandsadministratoren',
    addAssociationAdmin: (association: string) => `Administrator ${association} hinzufügen`,
    addAsAssociationAdmin: (association: string) => `Als ${association} Administrator hinzufügen`,
    confirmAddAssociationAdmin: (adminEmail: string, association: string) =>
      `${adminEmail} als ${association} Administrator hinzufügen?`,
    remove: 'Entfernen',
    removeAssociationAdmin: 'Verbandsadministrator entfernen',
    removeAsAssociationAdmin: 'Als Verbandsadministrator entfernen',
    confirmRemoveAsAssociationAdmin: (adminEmail: string) =>
      `${adminEmail} als Verbandsadministrator entfernen?`,
    disabledForAssociationAdmin:
      'Diese Funktionalität ist für die Vereins-Admin-Rolle (noch) nicht verfügbar.',
    functionalityDeactivated: 'Diese Funktionalität ist deaktiviert.',
  },
  users: {
    title: 'Benutzer',
    loadMore: 'Mehr laden',
    allUsersLoaded: 'Alle Benutzer sind geladen',
    editEmail: 'E-Mail bearbeiten',
    newEmailSaved: 'Neue E-Mail gespeichert',
    errorOnSaving: 'Fehler beim speichern',
    updateShortId: 'Short UIDs aktualisieren (für QR-Code)',
    duplicatedUsersNotice:
      'Achtung: diese Liste wird derzeit nicht sofort automatisch aktualisiert, sondern wird alle 2 Minuten neu geladen (Benutzer aktiv / inaktiv / Duplikatenerkennung)',
    searchDuplicatedUsers: 'Suchen (Name, Ort, PLZ, Mitgliedernummer)',
    updatePersonalData: 'Persönliche Daten bearbeiten',
    editUserEmail: (email: string) => `Email ${email} bearbeiten`,
    loginAsUser: (user: string) => `Anmelden als Benutzer ${user}`,
  },
  uploads: {
    newlyUploaded: 'Neu hochgeladen',
    verified: 'Verifiziert',
    invalidated: 'Invalidiert',
    notUploadedDeleted: 'Nicht hochgeladen / gelöscht',
    noteForRider: 'Notiz für Fahrer',
    internalNote: 'Interne Notiz',
    uploadedOn: 'Hochgeladen am',
    size: 'Grösse',
    type: 'Typ',
    resetToNewlyUploaded: 'Zurücksetzen zu neu hochgeladen',
    verify: 'Verifizieren',
    invalidate: 'Invalidieren',
  },
  count: 'Anzahl',
  chf: 'CHF',
  groupAssignments: {
    assignGroups: 'Gruppen zuteilen',
    downloadChampionshipStandings:
      'Der Meisterschaftsstand wird herunterladen, und dann können die Gruppen zugeteilt werden',
    startGroupAssignment: 'Gruppenzuteilung starten',
    groupSizeAndStatistics: 'Gruppengrösse definieren & Statistik',
    totalInscribed: 'Total (Eingeschrieben/Angemeldet)',
    numberOfGroups: 'x Fahrer pro Gruppe: Anzahl Gruppen',
    configuredGroupSize: 'Konfigurierte Gruppengrösse',
    largestGroupInTheory: 'Theoretisch grösste Gruppe',
    withConfiguredGroupSize: 'mit konfigurierter Gruppengrösse',
    deleteGroupAssignments: 'Alle Gruppenzuteilungen löschen',
    deleteGroupAssignmentOf: (name: string) => `Gruppenzuteilungen von ${name} löschen`,
    groupSize: 'Gruppengrösse',
    listOfInscriptionsAndGroups: 'Liste der Einschreiben und deren Gruppen',
    moveToHigherGroup: 'In höhere Gruppe versetzen',
    confirmMoveToHigherGroup:
      'Wirklich in höhere Gruppe versetzen? Falls es die Gruppe noch nicht gibt, wird sie erstellt',
    moveToLowerGroup: 'In tiefere Gruppe versetzen',
    confirmMoveToLowerGroup: 'Wirklich in tiefere Gruppe versetzen?',
    ranking: (myLapsName: string) => `Rangliste ${myLapsName}`,
    noRankingFound:
      'Keine Meisterschaft gefunden - wird nach Zufallsprinzip eingeteilt. Alternativ kann eine Rangliste der Starnummern (z.B. "23 91 7") angegeben werden.',
    assignAllGroups: 'Alle Gruppen zuteilen',
    validInscriptionNoticeLong:
      'Es werden nur Gruppen an gültigen Einschreiben zugeteilt. Bereits zugeteilte Gruppen werden nicht verändert. Wirklich einteilen?',
    validInscriptionNoticeShort:
      'Es werden nur Gruppen an gültigen Einschreiben zugeteilt. Bereits zugeteilte Gruppen werden nicht verändert.',
  },
}
