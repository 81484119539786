// Generated - edit here: https://docs.google.com/spreadsheets/d/13FODLia-TwZoE1XRSr7YiPgT4wt1SEIczgjKPcDNtA4
import { I18nType } from 'shared/i18n/i18n-types'

/* eslint-disable no-useless-escape */

export const en: I18nType = {
  language: 'en',
  finalized: 'Finalized',
  finalizedHint: 'Completed events can no longer enroll & deregister (billing done)',
  errorCodes: {
    approvedLicenseNotFound: 'Approved license not found',
    cannotDeleteLicenseWithExistingInscriptions:
      'Licenses with existing inscriptions cannot be deleted. Please delete the inscriptions first',
  },
  successful: 'Success',
  anUnknownErrorOccurred: 'An unknown error occurred',
  lastChange: 'Last change',
  inscriptionsDisabled: {
    title: 'Inscriptions for this category are disabled',
    hintEnlist: 'Field is full - maybe a different category still has space',
    hintUnlist:
      'The field is full and new inscriptions were no longer possible. For this reason, unlisting is also disabled',
    short: 'Inscriptions disabled',
  },
  showDeleted: 'Show deleted',
  combine: 'Combine',
  showOnlyDuplicates: 'Show only duplicates',
  newVersion: {
    available: 'A new version is available',
    reload: 'Reload',
  },
  dashboard: {
    title: 'Dashboard',
    adminTitle: 'Admin Dashboard',
    licenses: 'Licenses',
    users: 'Users',
    other: 'Other',
  },
  extended: 'Extended',
  adminArea: 'Admin area',
  toAdminArea: 'To admin area',
  done: 'done',
  email: 'Email',
  emailFirebase: 'Email Firebase',
  emailRacemanager: 'Email Racemanager',
  active: 'Active',
  name: 'Name',
  firstName: 'First name',
  lastName: 'Last name',
  hintForName:
    'The name can not be changed after submitting the license request. If you want to submit a license request for another person, you have to sign up with another email address.',
  street: 'Street',
  zip: 'Zip',
  place: 'Place',
  startsAt: 'Start',
  endsAt: 'End',
  alternativeStartsAt: 'Start (Alt.)',
  alternativeEndsAt: 'End (Alt.)',
  licensesAndDrafts: 'Licenses / License requests',
  categoryIds: 'Categories',
  categoryDate: 'Date Categories',
  categoryDateUnknown: 'According to racing schedule',
  offersPower: 'Offers power',
  cancelled: 'Cancelled',
  lastFiveScansOnThisDevice: 'Last scans at',
  suggestedDonationAmount: 'Suggested donation amount on cancellation',
  unlist: {
    buttonText: "Unlist / I don't start",
    dialogTitle: 'Really unlist',
    inProgress: 'Unlisting in progress...',
    dialogConfirmation: 'Unlist from the race and give up the starting place',
    dialogConfirmWithoutDonation: 'Really unlist',
    donationAmount: 'Donation amount',
    dialogConfirmDonation: (amount: string) => `Really unlist and donate CHF ${amount}`,
    donation: (amount: string) =>
      `The entry fee and day license will be transferred back to your Racemanager account. However, we would appreciate a donation of CHF ${amount} to cover part of the organizer's costs. The donation will be automatically deducted from your Racemanager account. You can adjust the voluntary amount up or down at your own discretion. If you don't want to donate anything, you can set the amount to 0. Thank you for your solidarity!`,
  },
  country: 'Country',
  birthdate: 'Date of birth',
  birthdateDe: 'Date of birth (DE)',
  birthdateTooYoung:
    'You are too young to submit a license for a license (or the date of birth is not right)',
  phone: 'Phone',
  comment: 'comment',
  licenseType: 'License type',
  licenseTypes: {
    national: 'National',
    international: 'International',
  },
  licenseTypesLong: {
    national: 'National license',
    international: 'International license',
  },
  newSamMember: {
    label: "I'm not an SAM member yet",
    labelAlternative: 'New SAM member?',
    validation: 'SAM membership',
    hintForNewMembers: 'This application is considered as an SAM membership application',
    hintForFine:
      'If you are already a member and the membership number is not entered, a fee will be charged by CHF 20. The 5- or 6-digit SAM member number (not license number) can be found on your SAM ID.',
  },
  samMemberNumber: {
    label: 'SAM members number',
    helperText: 'The 5- or 6-digit members number (not license number) can be found on your SAM ID.',
    requiredHelperText:
      'The members number must be specified. You will find the 5- or 6-digit members number (not license number) on your SAM ID.',
    hintForSubmittedLicense:
      'The members number can not be changed after submitting the license request. If you want to file a license application for another person, you have to sign up with another email address.',
  },
  password: 'Password',
  setPassword: 'Set new password',
  confirmSetPassword: 'Set password',
  licenseNumber: 'License number',
  afmSection: {
    label: 'AFM club',
    labelOptional: 'AFM club (optional)',
  },
  fmsSection: {
    label: 'Swiss Moto section',
    labelOptional: 'Swiss Moto section (optional)',
  },
  samSection: {
    label: 'SAM section',
    helperText: 'Please select the section you want to join',
    hintForSubmittedLicense:
      'The SAM section can not be changed on this platform. Please apply for the section change here:',
  },
  sidecarPartner: {
    label: 'Side car partner',
    hint: 'Name, first name, date of birth of the partner (for the identification of the team)',
    validation: 'Side car partner',
  },
  assignLicense: {
    title: 'Assign license',
    onlyRegisteredRiders:
      'Licenses can only be assigned to already registered riders. If you want to create a new rider, you have to log out as admin and log in with the email of the rider',
    tooManyResults: {
      title: 'Too many results',
      text: 'Please narrow the search further to show a selection of riders (e.g. via email, first name, last name, street, zip code, city, date of birth, ...)',
    },
  },
  licenses: {
    drafts: 'License drafts',
    approved: 'approved licenses',
    approvedLicenses: 'Confirmed licenses',
    unapprovedLicenses: 'Unconfirmed licenses',
    confirm: 'confirm',
    edit: 'edit',
    editLicenseBooking: 'Edit license booking',
    searchLicense: 'Search (name, place, zip code, membership number)',
    licenseRequests: 'License requests',
    licenseRequestsByCategoryType: 'Category type license requests',
    licenseRequestsByCategory: 'Category license requests',
    confirmed: 'Confirmed',
    unconfirmed: 'Unconfirmed',
    totalLicenseRequests: 'Total license requests',
    assignTransponder: 'Assign transponder',
    confirmLicense: 'Confirm license',
    cancelledBookingsError:
      'Error: Cancelled bookings may not be canceled more than once. This case has to be dealt with / fully implemented. Sorry :-(',
    importLicenses: 'Import licenses',
    licenseNotFound: 'License not found',
    licenseIdOrCodeMissing: 'License ID or code missing',
  },
  routes: {
    termsAndDataProtection: 'Imprint & data protection',
    licenseTerms: 'License terms',
    insuranceTerms: 'Insurance conditions',
    homepage: 'Website',
    homepages: 'Websites',
    dashboard: 'Dashboard',
    userDashboard: 'User dashboard',
    adminDashboard: 'Admin',
    allTransactions: 'All transactions',
    dashboardAllTransactions: 'KPIs',
    manualPayments: 'Manual payments',
    approvedLicenses: 'Confirmed licenses',
    assignLicense: 'Assign license',
    userFinancialsRiders: 'Account balance of rider',
    userFinancials: 'Account balences',
    licenseDrafts: 'License drafts',
    newTransponders: 'Transponder orders',
    licenseBookingsByRider: 'License bookings per rider',
    allLicenseBookings: 'KPIs license bookings',
    insurances: 'Insurances',
    allLicenses: 'KPIs licenses',
    payments: 'Payments',
    memberFees: 'Membership fees',
    manualBookingsAndCharges: 'Manual bookings and charges',
    manualBookings: 'Manual bookings',
    categoriesKeyFigures: 'License statistics',
    associationFinancials: 'Association financials',
    importLicenses: 'Import licenses',
    userEvents: 'User activity',
    newInscription: 'To inscription for',
    publicInscriptions: 'Starting lists',
    inscriptionGroups: 'Group assignments',
    lonelyTwoDayDiscounts: 'Lonely two-day discounts',
    userAccounts: 'User accounts',
    emailConflicts: 'Email conflicts',
    transactionsWithoutUser: 'Transactions without user',
    duplicatedUsers: 'Duplicated accounts',
    dbMigrations: 'DB migrations',
    administrators: 'Administrators',
    messages: 'Messages',
    changeYear: 'Change year',
    qrPitLane: 'Scan pit lane pass',
    previewLicense: 'License preview',
    qrLicense: 'Scan license',
    editProfile: 'Edit profile',
    editEmergency: 'Edit emergency info',
    editDriversLicense: 'Edit drivers license',
    editHealthCheck: 'Edit health check',
    editInsurance: 'Edit insurance',
    editPhoto: 'Edit photo',
    editTransponder: 'Edit transponder',
    changeEmailAfterLogin: 'Change email',
    newLicense: 'Request new license',
  },
  lastYear: 'Last year',
  noPreviousLicense: 'I had no license last year',
  addNewLicense: 'Add new license',
  editLicenseRequest: (categoryNameWithNumber: string) =>
    `Edit license request ${categoryNameWithNumber}`,
  editLicense: (categoryNameWithNumber: string) => `Edit license ${categoryNameWithNumber}`,
  acceptLicenseConditions: 'Accept license conditions',
  conditionsAcceptedByRider: 'Conditions and data protection declarations are accepted by the rider',
  noLicenseRequestAlert:
    'The rider has not yet submitted a license request, and therefore may have not accepted the license conditions yet. The rider should send the first license application themselves, so that they can read and accept the license conditions.',
  licenseRequestAlternative:
    'If this is not possible / necessary, please make sure of one of the following points:',
  licenseRequestAdminConfirmation: 'You made the rider aware of the license conditions',
  licenseRequestRiderConfirmation: 'He/she confirmed the license conditions in writing or by email',
  licenseRequestOtherConfirmation:
    'The rider has already accepted the licensing conditions in a different way (e.g. by purchasing a license with another account)',
  licenseRequestInternalNote:
    'Internal note: When and in what way did the rider accept the conditions and the data protection declarations?',
  editLicenseHelperText: 'Example 26.1.2021, 8:30 p.m., by email to lizenzen@s-a-m.ch',
  license: 'License',
  addLicense: 'Add license',
  saveLicenseRequest: 'Save license application',
  confirmLicenseRequest: (categoryNameWithNumber: string) =>
    `Confirm license application ${categoryNameWithNumber}`,
  licenseConfirmed: 'License confirmed',
  licenseRequestSummary: 'License request summary',
  licensesAndLicenseRequests: 'Licenses / license requests',
  confirmedLicenseForRider: 'Confirmed licenses of this person',
  otherLicensesWithSameNumber: 'Other licenses with the same start number',
  otherLicenseRequestsForRider: 'Other license requests of this person',
  or: '-- or --',
  lastYearLicenses: 'Licenses last year (SAM, Swiss Moto, MXRS, AFM, ...)',
  lastYearLicensesShort: 'Licenses last year',
  addCategory: 'Add category',
  lastYearCategories: 'Categories Last year (SAM National B, MXRS Race, ...)',
  lastYearCategoriesShort: 'Categories Last year',
  lastYearStartNumbers: 'Start numbers Last year',
  lastYearRanks: 'Annual final ranks last year',
  category: 'Category',
  categoryType: 'Category Type',
  sponsor: 'Team / Sponsor',
  bike: 'Motorcycle',
  bikes: 'Motorcycles',
  maxTwoBikes: 'A maximum of 2 motorcycles can be selected',
  chooseBikes: 'Choose motorcycles',
  section: 'Section / Association',
  domicile: 'Domicile',
  nationality: 'Nationality',
  association: 'Association',
  changeAssociation: 'Change association',
  categoriesAlt: 'Categories',
  dayCategories: 'Day categories',
  dayCategory: {
    title: 'Day category',
    transponder: 'Transponder',
    name: 'Name',
    hint: 'Hint',
    additionalTermsText: 'Additional Terms',
    additionalTermsURL: 'Additional terms url',
    price: 'Entry fee',
    needsInsurance: 'Needs insurance',
    needsEmergency: 'Needs emergency',
    needsHealthCheck: 'Needs health check',
    needsBikeInfo: 'Needs bike info',
    advertiseSamInsurance: 'Advertise SAM insurance',
    myLapsName: 'MyLaps Name',
    myLapsNameIfDifferent: 'MyLaps Name (if different from name)',
    sidecar: 'Sidecar',
    sidecarPassenger: 'Sidecar Passenger',
    surcharge: 'Surcharge',
    surchargeAfter: 'Surcharge After',
    discount: 'Discount',
    discountUntil: 'Discount Until',
    inscriptionsLimit: 'Inscriptions limit (e.g. 45)',
    waitlistLimit: 'Waitlist limit (e.g. 30)',
    pricePower: 'Price power',
    priceTransponderRental: 'Price transponder rental',
    sortPrimaryBy: 'Sort primary by',
    sortPrimaryByNames: {
      paymentDate: 'Payment date',
      enlistingDate: 'Enlistment date',
    },
    createDayCategory: 'Add day category',
    deleteDayCategory: 'Delete day category',
    deleteDayCategoryConfirmation:
      'Really delete? Existing inscriptions for this category will be automatically deleted.',
  },
  categories: (licenseYear) => `Desired categories for ${licenseYear}`,
  categoriesWithoutYear: 'Desired categories',
  categoryAlreadyChosenHint: 'Category has already been chosen via other association',
  categoryNotOnlineYet: 'Category is not online yet. Please try again later.',
  licenseAlreadyConfirmed: 'License is already confirmed',
  sportEvent: 'Event',
  sportEvents: 'Events',
  sportEventType: 'Event type',
  sportEventTypes: {
    '': 'All',
    enduro: 'Enduro',
  },
  sportEventTypeHint: 'For enduro events, number plate and driver license are mandatory',
  sportEventStatus: {
    online: 'Online',
    draft: 'Draft',
    deleted: 'Deleted',
  },
  sportEventFormErrors: {
    invalidStartOrEndDate: 'Date faulty: Please check the start and end of the event',
    setDateOrAnnounceLater:
      'Date of the categories faulty: Either the date will be announced later, or the date is set, not both together',
    noDateForCategory: 'Date of the categories faulty: At least one date per category must be selected',
    dateOutsideOfEventDates:
      'Date of the categories incorrect: Only dates during the event may be chosen',
  },
  hasTransponder: {
    label: 'I already have a MyLaps transponder',
    labelAlternative: 'Has MyLaps transponder',
    validation: 'Transponder',
    hintForNewTransponder: 'I order a MyLaps transponder',
  },
  orderedMyLapsTransponder: {
    labelSingular: 'I order the following transponder',
    labelPlural: 'I order the following transponders',
    labelAlternative: 'Ordered transponders',
    validation: 'Transponder',
  },
  transponders: {
    title: 'Transponder',
    transponderSystem: 'Transponder system',
    privateTransponder: 'Private transponder',
    borrowedTransponder: 'Lending transponder',
    transponder: 'Transponder',
    transponders: 'Transponder',
    hasTransponder: 'Transponder available',
    transpondersForm: {
      yourTranspondersTitle: 'Your transponders',
      buyTransponderTitle: 'Buy transponders',
      usedTransponders: 'Used transponders',
      perCategory: 'Depending on the category and regulations',
      needsToBeBought: 'a separate transponder must be bought',
      needsToBeRentedPerEvent: 'a transponder per event is provided for a fee',
      isGivenForFreePerEvent: 'a transponder per event is provided free of charge',
      pleaseInformYourself:
        'Please inform yourself in the corresponding regulations about handling if you do not have a transponder and do not want to buy one.',
      buyAdvertisment:
        'The most comfortable in any case is to order and use your own transponder. You can do that here:',
      batteryReminder: 'The battery of the RF Chronolec transponder must be replaced every 3 years',
      canBeEditedLater: 'You can edit your transponders later anytime',
    },
    transponderNames: {
      subscriptionOneYear:
        'MyLaps X2 / TR2 MX, 1-year subscription, motocross SAM/MXRS/SJMCC and supermoto SAM/Swiss Moto',
      subscription:
        'MyLaps X2 / TR2 MX, 5-year subscription, motocross SAM/MXRS/SJMCC and supermoto SAM/Swiss Moto',
      purchase: 'Mylaps TR2, purchase variant, motocross SAM/MXRS/SJMCC and supermoto SAM/Swiss Moto',
      rfPurchase: 'Chronolec RF (via Swiss Moto), purchase variant, motocross AFM/Swiss Moto',
      rfPurchaseAfm: 'Chronolec RF (via AFM), purchase variant, motocross AFM/Swiss Moto',
    },
    editTransponder: 'Edit transponder',
    orderOptionalTransponder: 'I want to add or order a transponder',
    transponderOrders: 'Transponder orders',
    orderedTransponderIds: 'Ordered transponder IDs',
    orderedTransponders: 'Ordered transponders',
  },
  transponderMake: {
    label: 'Transponder: Make & Type (e.g., MyLaps X1)',
    labelAlternative: 'Transponder: Make & Type',
  },
  transponderNumber: {
    label: 'MyLaps transponder number',
    labelInscription: 'Mylaps transponder number (personal transponder)',
  },
  transponderNumbers: {
    label: 'MyLaps transponder numbers',
  },
  transponderNumbersInput: {
    label: 'MyLaps transponder numbers (separated by comma, e.g. 123, 456)',
  },
  hasTransponderRf: {
    label: 'I already have a Chronolec RF transponder',
    labelAlternative: 'Has RF transponder',
    validation: 'Transponder',
  },
  transponderNumberRf: {
    label: 'Chronolec RF transponder number',
  },
  transponderNumbersRf: {
    label: 'Chronolec RF transponder numbers',
  },
  transponderNumbersRfInput: {
    label: 'Chronolec RF transponder numbers (separated by comma, e.g. 123, 456)',
  },
  bikeMake: {
    label: 'Machine brand (e.g. Kawasaki)',
    labelShort: 'Machine brand',
  },
  numberPlate: {
    label: 'Number plate',
    hint: 'e.g. ZH 12345',
  },
  bikeInsurance: {
    label: 'Motorcycle insurance',
    hint: 'e.g. Allianz',
  },
  bikeModel: {
    label: 'Machine model (e.g. KX450)',
    labelShort: 'Machine model',
    hint: 'If not obvious from model or if not original: cylinder capacity [ccm]',
  },
  frameNumber: 'Frame number',
  bikeCylinderDisplacement: 'Cylinder capacity [ccm]',
  preferredNumber: {
    label: 'Desired number',
    labelAlternative: 'Start number',
    helperText: 'e.g. "7, or two digits, at least one digit with the number 7"',
  },
  issuedNumber: {
    label: 'Definitive start number',
    labelAlternative: 'Start number',
  },
  ahvNumber: 'AHV number / social insurance number',
  civilStatus: {
    label: 'Civil status',
    single: 'Single',
    married: 'Married',
    divorced: 'Divorced',
    widowed: 'Widowed',
    registeredPartnership: 'Registered partnership',
    other: 'Other',
  },
  confession: 'Confession',
  profession: 'Job title',
  doctorName: 'Doctor',
  doctorPhone: 'Phone number of doctor',
  employerName: 'Employer',
  employerPhone: 'Phone number of employer',
  accidentInsurance: 'Accident insurance',
  accidentInsuranceName: 'Accident insurance',
  accidentInsurancePolicyNumber: 'Policy number of accident insurance',
  accidentInsurancePlan: {
    label: 'Hospital department for accident insurance',
    general: 'General',
    halfPrivate: 'Half-private',
    private: 'Private',
    validation: 'Please choose a hospital department for accident insurance',
  },
  healthInsurance: 'Health insurance',
  healthInsuranceName: 'Health insurance',
  healthInsurancePolicyNumber: 'Policy number of health insurance',
  healthInsurancePlan: {
    label: 'Hospital department for health insurance',
    general: 'General',
    halfPrivate: 'Half-private',
    private: 'Private',
    validation: 'Please choose a hospital department for health insurance',
  },
  additionalInsurances: 'Additional insurance',
  hasSupplementaryInsurance: {
    label: 'I have an additional insurance',
    validation: 'Additional insurance',
    hintForSupplementaryInsurance:
      'In the SAM license is an additional included, and if you have ordered a SAM license, additional insurance may not be necessary anymore',
  },
  hasReturnTransportInsurance: {
    label: 'I have a return transport insurance',
    validation: 'Return transport insurance',
    hintForNewReturnTransportInsurance: 'I have no return transport insurance',
  },
  returnTransportInsuranceName: 'Name of return transport insurance (e.g. Rega)',
  contactOnAccident: 'Contact in case of accident',
  contactOnAccident1Name: 'Person 1: Name',
  contactOnAccident1Phone: 'Person 1: Phone number',
  contactOnAccident2Name: 'Person 2: Name',
  contactOnAccident2Phone: 'Person 2: Phone number',
  medicalInformation: 'Medical information',
  medication: 'Current permanent medication',
  allergies: 'Known allergies against medicines',
  infoForDoctor: 'Other information for rescue service / doctor',
  additionalInformation:
    'The following information can be given voluntarily and is treated strictly confidential:',
  bloodGroup: 'Blood type',
  bloodRhesusFactor: 'Rhesus factor',
  specialInformation: 'Further information',
  confirmRevokeConfidentiality: {
    label:
      'I free a doctor, rescue service and hospital in an incident from medical secrecy against members of the rescue chain, the sports commission, OK president, race management and emergency contact',
    validation: 'The conditions must be accepted to participate in races',
  },
  remarks: 'Remarks',
  remarksRider: 'Remarks rider',
  remarksAdmin: 'Remarks admin',
  licenseRequestSent: 'License application submitted on',
  internalRemarks: 'Internal remarks',
  parentsInfo: 'First name, last name, telephone of the guardian',
  parentsAgree: {
    label: 'This form was filled in by the parent or guardian',
    validation: 'This form must be filled in by the parent or guardian',
  },
  acceptTerms: {
    label: 'I have read the conditions and the privacy policy and approve',
    validation: 'Conditions and privacy policy',
    additionalTerms: 'Additional Terms',
    hintForTerms: 'Read the documents here:',
    hintForTermsTerms: 'Conditions & privacy',
    hintForTermsDataProtection: 'Privacy',
    hintForTermsError: 'The conditions and privacy policy must be accepted to buy a license',
  },
  photoPath: "Photo (only for those without the previous year's license)",
  riderWeight: 'Weight of pilot with gear',
  bikeName: {
    label: 'Name of the motorcycle (optional)',
    hint: 'For you to identify, e.g. "Race bike", "Replacement" or "Bike 2020"',
  },
  teamName: {
    label: 'Team (optional)',
    labelShort: 'Team',
  },
  bikeFrame: 'Frame chassis brand / type',
  bikeMotorName: 'Brand brand / engine name',
  bikeMotorNumber: 'Motor number (the first 6 digits)',
  bikeIntake:
    'Inlet system (slit controlled, ZME ex factory [Cylinder membrane inlet], conversion to ZME, GME [housing membrane inlet])',
  bikeCylinderCooling: 'Cylinder brand (air-cooled, water-cooled)',
  bikeCylinderIsOriginal: 'Cylinder original? (yes, no)',
  bikeIgnition: 'Ignition system (breaker, electronic)',
  bikePistonSize: 'Piston diameter [mm]',
  bikeCarburetorSize: 'Carburetor size [mm]',
  bikeCrankshaftIsOriginal: 'Crankshaft hub (such as motor original) [mm]',
  bikeCrankshaftModified: 'Crankshaft hub (not motor original) [mm]',
  acceptBikeTerms: {
    label: (licenseYear: number) =>
      `I confirm that my equipment (e.g., protective clothing, helmet) and my motorcycle agree with the corresponding regulations ${licenseYear} of my category (for club races, the respective regulations apply). I confirm that I will carry the full protective clothing without exception in any workout and race of an event. I confirm that I am responsible for the safety of my motorcycle.`,
    validation:
      'In order to increase your security and the security of your fellow riders, you must comply with the respective rules to participate in races',
  },
  table: {
    oneEntryIsDisplayed: 'One entry is displayed',
    showingNEntries: (amount: number) => `${amount} entries are displayed`,
    showAllEntries: (amount: number) => `Show all ${amount} entries`,
    manyEntriesWarning: '(many entries => Excel Export may be better suited for that much data)',
  },
  associationPayments: {
    title: 'Association payments',
    add: 'Add association payment',
    delete: 'Delete association payment',
    downloadSelectedPayments: (amount: number) => `Download ${amount} selected payments`,
    requestPayment: 'Request payment to association',
    states: {
      requested: 'Requested payment',
      paid: 'Paid',
    },
  },
  accountBalance: 'Balance in CHF',
  accountBalanceAssociation: 'Association balance (according to filter)',
  accountBalanceChangeAssociation: 'Association balance change',
  noAccountBalanceBookings: 'No bookings',
  addManualPaymentButtonText: (amount: string) => `Collect ${amount}`,
  transactionsKPIs: 'Transactions KPIs',
  accountBalanceCHF: 'Balance in CHF',
  processedAt: 'Processed at',
  createdAt: 'Created at',
  updatedAt: 'Updated at',
  actions: 'Actions',
  amount: {
    label: 'Amount',
    hint: 'Negative: credit, positive: debit',
  },
  amountOpen: 'Thereof still open',
  amountOpenPosition: 'Open',
  amountTable: 'Amount',
  amountTableCHF: 'Amount in CHF',
  sportEventFinancialsAmounts: {
    total: 'Total earnings',
    inscription: 'Thereof entry fee',
    power: 'Thereof electricity (supermoto)',
    dayLicense: 'Thereof day license',
    donation: 'Thereof donation',
    inscriptionDiscount: 'Thereof 2-day-discount',
    open: 'Unpaid earnings',
  },
  createBooking: 'Book',
  createBookingAndBill: 'Book and create bill',
  reverseBooking: 'Unbook',
  transactionTypeNames: {
    licenseBooking: 'License booking',
    reverseLicenseBooking: 'License chargeback',
    inscriptionBooking: 'Inscribe',
    manualBooking: 'Manual booking',
    payment: 'Automatic payment',
    manualPayment: 'Manual payment',
    bill: 'Invoice',
    associationPayment: 'Association payment',
  },
  lineItemNames: {
    inscriptionLineItem: 'Entry fee',
    inscriptionDayCategoryLineItem: 'Entry fee (single event)',
    inscriptionDayLicenseLineItem: 'Entry fee (day license)',
    powerLineItem: 'Power',
    inscriptionDayCategoryPowerLineItem: 'Power',
    categoryLineItem: 'Category',
    transponderLineItem: 'Transponder',
    donationLineItem: 'Donation',
    inscriptionDiscountLineItem: '2-day-discount',
  },
  signIn: {
    popupOpened: 'Popup for the registration has been opened',
    signInWithGoogle: 'Sign in with Google',
    signInWithFacebook: 'Sign in with Facebook',
    emailSentLong: 'E-mail has been sent. Please check your email and click on the link to register.',
    emailSentShort: 'Email sent',
    signInWithPassword: 'Sign in with password',
    signInWithEmail: 'Sign in with email',
    backToSignIn: 'Back to Sign In',
    emailNotSent: 'E-mail was not sent. Please chose a different registration option.',
    verifyEmail: (email: string) => `Verify e-mail \"${email}\"`,
    verificationEmailSent: (email: string) =>
      `E-mail has been sent. Please check your mails and click on the link to confirm \"${email} \".`,
    creatingProfile: "Hang on, we're creating your profile...",
    loading: 'Loading app...',
    loadingUser: 'Loading user...',
    loadingDocuments: 'Loading documents...',
    loadingUserData: (email: string) => `Loading user data of ${email}...`,
  },
  signInText: 'Sign in',
  signOutText: 'Sign out',
  inscribe: 'Inscribe',
  inscribeFor: 'Inscribe for',
  inscribeIn: 'Inscribe in',
  inscribeAtDateUnknown: 'Inscribe (according to racing schedule)',
  user: 'Rider',
  userID: 'Rider ID',
  admin: 'Admin',
  adminID: 'Admin ID',
  id: 'ID',
  datetime: 'Date / time',
  datetimeISO: 'Date ISO',
  bookingType: 'Type',
  bookingSubtype: 'Subtype',
  bookingItemName: 'Booking entry',
  bookingText: 'Booking text',
  manualPaymentTag: 'Tag (internal filter)',
  manualBookingTag: 'Tag (internal filter)',
  licenseStatus: 'Status',
  uid: 'UID',
  photo: 'Photo',
  displayName: 'Display name',
  creationTime: 'Registered at',
  lastSignInTime: 'Last sign in',
  customClaims: 'Roles',
  date: 'Date',
  position: 'Position',
  reference: 'Reference',
  referenceParts: 'yymmdd - hhmmss - Membership number - Random - Checksum (possibly moved)',
  status: 'Status',
  open: 'Open',
  paid: 'Paid',
  importStatus: 'Import',
  parsingStatus: 'Parsing',
  sheetStatus: 'Modification',
  details: 'Details',
  debitor: 'Paid by / for',
  bill: 'Invoice',
  iban: 'IBAN',
  healthCheck: {
    title: 'Health check',
    status: {
      notUploaded: 'Not uploaded (completely)',
      uploaded: 'Uploaded',
      verified: 'Confirmed',
      invalid: 'Objected by admin, please upload again',
    },
    downloadAndPrintOptionA: 'Option A: International license health check (EN) download and print',
    downloadAndPrintOptionB: 'Option B: International license health check (FR) download and print',
    downloadAndPrintOptionC: 'Option C: National license: Self-declaration download and print',
    instructions: {
      fillInByDoctor: 'Let the doctor fill out and let him sign',
      fillInPersonalDataAndSign: 'Fill and sign personal stores',
    },
    takePictureOrUpload: 'Photograph / upload',
    page: 'Page',
    deletePage: (page) => `Delete page ${page}`,
  },
  provideDocuments: {
    status: 'Status:',
    optionATakePictureWithPhone: 'Option A: Take picture with phone (see below)',
    optionBScanAndUploadOnComputer: 'Option B: Scan and upload from computer (see below)',
    checkReadabilityAndSignature: 'Check readability and signatures',
    qualityHint:
      "Please make sure that the forms are well readable. In the case that we can't read the forms properly, you will have to upload them again later",
    errorTooManyFilesSelected: 'Too many files selected for page',
  },
  insurance: {
    title: 'Insurance confirmation',
    shortTitle: 'Insurance',
    status: {
      notUploaded: 'Not uploaded',
      uploaded: 'Uploaded',
      verified: 'Confirmed',
      invalid: 'Objected by admin, please upload again',
    },
    chooseInsuranceConfirmationType: 'Select insurance confirmation:',
    optionAForEmployees: 'For employees according to Swiss UVG',
    optionBForSelfEmployed: 'For self-employed riders according to Swiss UVG',
    optionCForOthers: 'For all other riders',
    downloadInsuranceConfirmation:
      'Download and print the insurance confirmation, or send it electronically',
    fillInByEmployer: 'Insurance confirmation to be filled in and signed by employer',
    uploadInsuranceConfirmation: 'Insurance confirmation: Take picture / upload',
    deleteInsuranceConfirmation: 'Delete insurance confirmation',
  },
  pdfInsurance: {
    generatedOn: 'Generated on',
    insuranceCompanyAndPoliceNumber:
      'Insurance company, policy number .................................................................................................',
    fillInByEmployer: {
      title: 'To be filled in by employer',
      companyAddress:
        'Company / address .........................................................................................................................',
      confirmAccidentInsurance: (riderName: string, formattedBirthdate: string) =>
        `We hereby confirm that ${riderName} ${formattedBirthdate} is insured against non-work accidents according to the UVG.`,
      datePlaceCompanySignature:
        'Date, place, signature by company ..................................................................................................',
    },
    fillInByRider: {
      title: 'To be filled in by rider',
      confirmAccidentInsurance: (riderName: string, formattedBirthdate: string) =>
        `I hereby confirm that I, ${riderName} ${formattedBirthdate} am insured against non-work accidents according to the UVG.`,
      datePlaceRiderSignature:
        'Date, place, signature by rider .........................................................................................................',
      confirmAccidentInsuranceOther: (riderName: string, formattedBirthdate: string) =>
        `I hereby confirm that I, ${riderName} ${formattedBirthdate} suis assuré contre les accidents non professionnels équivalent à la UVG suisse. Je confirme notamment que les frais de guérison après un accident sont couverts par mon assurance.`,
    },
    selfDeclaration: {
      title: 'License request | Self-declaration for national license',
      confirmTitle: (riderName: string, formattedBirthdate: string) =>
        `I, ${riderName}, born on ${formattedBirthdate}, hereby declare in accordance with applicable law:`,
      bulletPoint1:
        'I apply for the issuance of a national racing license in accordance with the provisions of Swiss Moto.',
      bulletPoint2:
        'I confirm that I am in full possession of my mental faculties and that there are no health impairments or disabilities that could affect my ability to act safely as a racing driver.',
      bulletPoint3:
        'I am aware that false or incomplete information about my mental or physical health may have criminal and civil as well as insurance consequences.',
      bulletPoint4:
        'I agree to undergo all necessary medical examinations that may be required by the competent authorities or in accordance with the provisions of Swiss Moto.',
      bulletPoint5:
        'I will inform Swiss Moto immediately of any changes in my health status, especially changes which could impact my driving ability at a race, training or other events related to my license.',
      endParagraph:
        'This self-declaration is an integral part of my license application and may be used by Swiss Moto for verification purposes. An incomplete or incorrect self-declaration will in any case result in the immediate withdrawal of the license, as well as the exclusion from championships, including the cancellation of accumulated points.',
      datePlaceRiderSignature:
        'Date, place, signature by rider .........................................................................................................',
    },
  },
  licensePhoto: {
    title: 'Profile photo',
    status: {
      notUploaded: 'Not uploaded',
      uploaded: 'Uploaded',
      verified: 'Verified',
      invalid: 'Objected by admin, please upload again',
    },
    options: {
      optionAMakeASelfie: 'Option A: Take a selfie with your phone (see below)',
      optionBUploadFromComputer: 'Option B: Upload photo from computer (see below)',
      checkQuality: 'Check the quality of the photo',
    },
    qualityHint:
      "Please make sure that the photo's quality is good. Should this not be the case, you'll have to upload it again later.",
    uploadPhoto: 'Profile photo: take picture / upload',
    deletePhoto: 'Delete photo',
    invalidPhoto: 'The uploaded file was found to be invalid by the administrator',
    uploadAgain: 'Please upload the improved file again.',
    saveLicensePhoto: 'Save profile photo',
  },
  categoryTypeNames: {
    other: 'Other',
    motocross: 'Motocross',
    supercross: 'Supercross',
    mofacup: 'Mofacup',
    mofacross: 'Mofacross',
    pitbikecross: 'Pitbikecross',
    minibike: 'Minibike',
    trial: 'Trial',
    supermoto: 'Supermoto',
    roadRacing: 'Road Racing',
    iceRace: 'Ice-Race',
    snx: 'SNX',
    dragster: 'Dragster',
    vintage: 'Vintage',
    track: 'Track',
    enduro: 'Enduro',
    enduroCrossCountry: 'Enduro / Crosscountry',
    bergrennen: 'Bergrennen',
    eMoto: 'eMoto',
    otherFms: 'Other',
    omc: 'OMC',
  },
  categoryTypeNamesLong: {
    otherFms: 'One Event / Fun Sport alle Disziplinen',
  },
  importStatusOptions: {
    done: 'Matched',
    open: 'Import open',
    outdatedPersonalData: 'Personal data is not consistent',
    locked: 'No action possible',
  },
  none: 'None',
  parsingStatusOptions: {
    success: 'OK',
    error: 'Error',
  },
  sheetStatusOptions: {
    associate: 'Create',
    delete: 'Delete',
    missingOrUnpaid: 'Info is missing or not paid',
    unknown: 'Unknown / faulty',
  },
  messages: {
    recipient: 'Receiver (all, division, category)',
    type: 'Mail type / Template',
    simulate: 'Preview (e-mails are not sent)',
    location: {
      label: 'Venue',
      hint: 'e.g. Armeno (Piedmont I)',
    },
    date: {
      label: 'Date',
      hint: 'e.g. Saturday, 24 April 2021 - Sunday, 25th of April 2021',
    },
    sportEvent: 'Event',
    sendMessages: 'Send messages',
    generatingPreview: 'Preview is generated ...',
    generatePreview: 'Generate preview',
    sendingEmail: 'Sending messages...',
    confirmSendEmail: 'Confirm sending email',
    emailPreview: 'Email preview / sent emails',
    sent: 'Sent',
    preview: 'Preview',
  },
  internalError: 'An unexpected error has occurred. Please contact the support.',
  inscription: {
    group: 'Group',
    title: 'Inscribe',
    inscriptionType: 'Type',
    inscriptionTypes: {
      enlistedLicenseInscription: 'System-validated inscription / inscription for annual category',
      enlistedDayInscriptionYearCategoryDraft: 'Unconfirmed inscription for year category / day license',
      enlistedDayInscriptionYearCategory: 'Confirmed inscription for year category / day license',
      enlistedDayInscriptionDayCategoryDraft: 'Unconfirmed inscription for day category / club category',
      enlistedDayInscriptionDayCategory: 'Confirmed inscription for day category / club category',
      unlistedLicenseInscription: 'Unlisted annual license for annual category',
      unlistedDayLicenseInscription: 'Unlisted day license',
    },
    pendingTasksDescription: 'but the following is still missing',
    yearLicenseRequired: 'A year license is required for this category',
    dayLicenseInThisCategoryTemporarilyDisabled:
      'The day licenses in this category are not available yet. Please try again 2 weeks before the event',
    enlistedOnWaitlist: 'logged in, but not yet confirmed / on the waiting list',
    payAndInscribe: 'Inscribe for a fee',
    inscribing: 'Inscribing...',
    inscribeFor: 'Inscribe for',
    editInscriptionBooking: 'Edit inscription booking',
    editInscriptionBookingTooltip: 'Edit inscription booking',
    saveInscriptionBooking: 'Save inscription booking',
    deleteInscriptionConfirmation: 'Delete?',
    deleteInscriptionTitle: (fullName: string) => `Delete inscription of ${fullName}`,
    deleteInscriptionInfo: (fullName: string, sportEvent: string) =>
      `Delete inscription of ${fullName} for ${sportEvent}. The inscription bookings will also be deleted, but the generated bills are not (yet) deleted. Any fines must be entered manually.`,
    deleteInscriptionTooltip: (sportEvent: string) => `Delete inscription for ${sportEvent}`,
    migrateInscriptionBookings:
      'Migrate inscription bookings. This only has to be done 1x, button will be removed. It takes a few seconds.',
    inscriptionDetails: 'Inscription details',
    inscriptionStatistics: 'Inscription statistics',
    addNewInscription: 'Add new inscription',
    addInscription: 'Add inscription',
    editInscription: 'Edit inscription',
    fineForLastInscription: 'Add fine for last inscription',
    fineForInscription: (categoryName: string, sportEvent: string) =>
      `Add fine for inscription in ${categoryName} / ${sportEvent}`,
    inscriptionCurrentlyDeactivated: 'This function is currently deactivated (inscription for admin)',
    categoryNotStartingIn: (category: string, sportEvent: string) =>
      `Category ${category} does not race in ${sportEvent}`,
    modifySportsEventInscription: (inscriptionType: string, sportEvent: string) =>
      `Confirm / edit / cancel ${inscriptionType} of ${sportEvent}`,
    confirmManually: 'Confirm manually (all other system checks will be deactivated)',
    removeManualConfirmation: 'Remove confirmation (the system checks will be reactivated)',
    noTransponderRegistered: (transponderType: string) => `No ${transponderType} registered`,
    noTransponderAssigned: (transponderType: string) => `No ${transponderType} assigned`,
    confirmRegistration: 'Confirm registration',
    enlistInscriptionInfo: (numberOfRiders: number, numberOfPayments: number, riderLimit: number) =>
      `${numberOfRiders} riders are enrolled. Payments have been received from ${numberOfPayments} riders. A maximum of ${riderLimit} riders can race in this category.`,
    enlistInscriptionOrderInfo:
      'Inscriptions are primarily prioritized based on the receipt of payment. If two payments arrive at the same time, the inscription date is the second priority. As long as your payment has not been received on our account, your starting place can be assigned to other riders who pay before you.',
    enlistPaymentInfo:
      'If you pay and cannot race because the field is full, your payment will be credited to the Racemanager. With this credit you can register for the next race.',
    enlistWaitlistInfo:
      'If the field is already full at the receipt of your payment, you will go on the waiting list. If a space is then available spontaneously, you are automatically registered for the race. In this case you can no longer reclaim your money.',
    enlistPaidRidersInfo: (numberOfPaidRiders: number, inscriptionLimit: number) =>
      `${numberOfPaidRiders} riders have paid, but a maximum of ${inscriptionLimit} riders can race.`,
    enlistOnWaitlistInfo:
      "This is why your inscription goes on the waiting list. You can only hope that riders who have already paid will unregister. Otherwise you can't start.",
    enlistRiderLimitInfo: (numberEnlisted: number, inscriptionLimit: number) =>
      `There are ${numberEnlisted} riders enrolled, but a maximum of ${inscriptionLimit} riders can race.`,
    enlistWaitlistPaymentInfo:
      "This is why your inscription goes on the waiting list. If the other riders pay before you or at the same time, you won't be able to start. If you pay and another registered rider doesn't, you will get his place.",
    enlistInscriptionOrderRules: (numberOfRiders: number, numberOfPaidRiders: number) =>
      `${numberOfRiders} riders are registered. We've received payments from ${numberOfPaidRiders} riders. The racing places are limited depending on the event / association / tracks, and licensed riders have priority depending on the regulations of the respective category. Please read these regulations before you contact the corresponding association. Thanks.`,
    enlistDayLicenseInfo:
      'If free spaces are available after licensed riders are registered, additional inscriptions are primarily prioritized based on receipt of payment. If two payments arrive at the same time, the inscription date is the second priority. As long as your payment has not been received in our account, your starting place can be assigned to other riders who pay before you.',
    updatingInscriptions: 'Updating inscriptions...',
    moveInscriptionsError: 'Unable to move (all) inscriptions',
    moveOrDeleteInscriptionNotice: 'The following registrations are postponed or deleted',
    categoryId: 'Category ID',
    from: 'From',
    to: 'To',
    collect: 'Collect',
    raceDate: 'Racing date',
    riderRemarks: 'Remarks rider',
    adminRemarks: 'Remarks admin',
    unlistedInscriptionCreatedAt: 'Sign off on',
    enlistedInscriptionCreatedAt: 'Registration on',
    sidecar: 'Side car',
    noPartnerAssigned: 'No partner assigned yet',
    sideCarPartnerLabel: 'Side car partner (<last name> <itame>)',
    assignPartner: 'Assign partner',
    openTasks: 'Open tasks',
    manualConfirmation: 'Manual confirmation',
    manuallyVerified: 'Manually verified',
    verifiedBySystem: 'Verified by the system',
    addFineByAssociation: 'Add fine (association)',
    fromRequestedLicense: 'From requested license',
    confirmInscription: (issuedNumber: number) =>
      `Confirm registration with start number ${issuedNumber} confirm`,
    bikeMakeLabel: 'Bike make',
    inscriptionDeactivatedFor: 'Inscription deactivated for',
    unsubscribeNotPossibleAnymoreTitle: (sportEventName: string) =>
      `Unsubscribe not possible anymore for ${sportEventName}`,
    unsubscribeNotPossibleAnymoreText: (sportEventName: string) =>
      `Unsubscribing from ${sportEventName} is no longer possible via the racemanager. Please contact the organizer or the secretariat if you want to unsubscribe`,
    filterByStatus: 'Filter by status',
    filterByType: 'Filter by type',
  },
  search: 'Search (name, category, #7, UID, association,',
  chooseCategoryAndDay: 'Choose category / day',
  detailsAndEdit: 'Details / edit',
  searchRider: 'Search rider',
  selectedRiderTooltip: 'This driver is already selected => scroll up',
  deleteEntry: 'Delete entry',
  searchRiderLabel: 'Search (name, date of birth, UID, zip code, location, mbrnr)',
  myLaps: 'MyLaps',
  exportForMyLaps: 'Export for MyLaps',
  exportMyLaps: 'Export for MyLaps',
  exportAlertTooltip:
    'Attention: If a category races on both days, it is not clear from this export on which day the rider races. Riders who race on both days appear twice in this export.',
  formatByEventAndCategories: 'Format according to the event and categories',
  assignNoRaceDay: 'No race day assigned',
  all: 'All',
  formatOptionSpecial: 'Format: LAST NAME, first name, place (without zip)',
  formatOptionNormal: 'Format: first name, last name, zip code, place',
  personalData: 'Personal data',
  editPersonalData: 'Edit personal data',
  unknown: 'unknown',
  qrCodeScanNotAvailable: 'ImageCapture not available in your browser/os at the moment',
  scanning: 'Scanning...',
  scanCancelled: 'QR scan cancelled',
  scanNotCompleted: 'QR code could not be scanned',
  qrScan: 'QR scan',
  qrScanLong: 'Scan license or pit lane pass',
  tryAgain: 'Try again',
  showOnlyCategoriesWithLicense: 'Show only categories of my licenses / registrations',
  startList: 'Starting list',
  startLists: 'Starting lists',
  updatePublicStartLists: 'Update / align public start list',
  for: 'for',
  dayLicense: 'Day license',
  issue: 'issue',
  deleted: 'deleted',
  unpaid: 'unpaid',
  incomplete: 'incomplete',
  twoDayDiscount: '2-day-discount',
  dayUnconfirmedDate: 'according to race schedule',
  inscriptionStatusLong: {
    'not-inscribed': 'Not signed up',
    unlisted: 'Opted out',
    inscribed: 'Enrolled',
    'inscription-pending': 'Registered',
  },
  inscriptionTypeSearch: {
    dayLicense: 'DL',
    dayCategory: 'Club',
    license: 'License',
    unlisting: 'Unlisted',
  },
  inscriptionStatusShort: {
    'not-inscribed': 'Not sign.up.',
    unlisted: 'Opted out',
    inscribed: 'Enrolled',
    'inscription-pending': 'Incomplete',
  },
  kpiTitles: {
    totalBalances:
      'Total accounts of all riders, without received QR payments (positive = debt of rider to association)',
    totalBalanceWithouLicenseBookings:
      'Total accounts of all riders, without received QR payments (positive = debt of rider to association), minus open licenses',
    totalAutomaticPayments: 'Total automatically recorded payments',
    totalManualPayments: 'Total manually recorded payments',
    totalInscriptionBookings: 'Total Inscriptions',
    openInscriptionBookings: 'Open inscriptions',
    paidInscriptionBookings: 'Paid inscriptions',
    totalLicenseBookings: 'Total licenses',
    openLicenseBookings: 'Open licenses',
    paidLicenseBookings: 'Paid licenses',
    totalManualBookings: 'Total manual bookings',
    totalTransponderBookings: 'License bookings: Total transponders',
    openTransponderBookings: 'License bookings: Open transponders',
    paidTransponderBookings: 'License bookings: Paid transponders',
    totalCategoryBookings: 'License bookings: Total license fees',
    openCategoryBookings: 'License bookings: Open license fees',
    paidCategoryBookings: 'License bookings: Paid license fees',
    totalCategoryBookingsWithInsurance: 'License bookings full license: Total license fees',
    openCategoryBookingsWithInsurance: 'License bookings full license: Open license fees',
    paidCategoryBookingsWithInsurance: 'License bookings full license: Paid license fees',
    totalAmountCategoryBookingsWithInsurance:
      'License bookings full license: Number of total licenses (not CHF)',
    openAmountCategoryBookingsWithInsurance:
      'License bookings full license: Number of open licenses (not CHF)',
    paidAmountCategoryBookingsWithInsurance:
      'License bookings full license: Number of paid licenses (not CHF)',
    totalInsuranceCategoryBookingsWithInsurance:
      'License bookings full license: Total insurance contributions',
    openInsuranceCategoryBookingsWithInsurance:
      'License bookings full license: Open insurance contributions',
    paidInsuranceCategoryBookingsWithInsurance:
      'License bookings full license: Paid insurance contributions',
    totalAmountCategoryBookingsSecondary:
      'License bookings without insurance: Number of total licenses (not CHF)',
    openAmountCategoryBookingsSecondary:
      'License bookings without insurance: number of open licenses (not CHF)',
    paidAmountCategoryBookingsSecondary:
      'License bookings without insurance: Number of paid licenses (not CHF)',
    licenseBookingKPIs: 'License bookings KPIs',
    licenceBookingsByCategoryType: 'License bookings according to category type',
  },
  accountOverview: 'Overview',
  financials: {
    financials: 'Financials',
    financialsFor: 'Financials for',
    amountReceived: 'Amount received',
    amountPaid: 'Amount paid',
    debit: 'Debit',
    credit: 'Credit',
    open: 'Open',
    paidOn: 'Paid on',
    payment: 'Payment',
    overpayment: 'Overpayment',
    underpayment: 'Underpayment',
    replaced: 'Replaced by newer bill',
    inscriptionDiscount: 'Discount credited',
    priceRangeTextBetween: (from: string, to: string) => `Date of birth between ${from} and ${to}:`,
    priceRangeTextOlderThan: (from: string) => `Older than ${from}:`,
    priceRangeTextYoungerThan: (to: string) => `Younger than ${to}:`,
    manualBookingsAndCharges: 'Manual credits & charges',
    newManualBookingTitle: 'New manual booking',
    newManualBooking: 'New manual booking',
    saveNewManualBooking: (bookingKind: string) => `Save ${bookingKind}`,
    deleteManualBooking: (bookingItemName: string) => `Delete manual booking ${bookingItemName}`,
    editManualBookingButton: 'Edit manual booking',
    editManualBooking: (bookingKind: string) => `Edit manual ${bookingKind}`,
    saveManualBooking: (bookingKind: string) => `Save ${bookingKind}`,
    copyBooking: 'Copy booking',
    bookingsOnRiderChanged:
      'Attention: If the rider is changed, a new booking will be created and the old booking must be deleted manually.',
    riderChanged: 'Rider changed',
    generateNewBill: 'Generate new bill',
    eventFilter: 'Show / hide events',
    cashOnEvent: 'Cash payments from events',
    selectEvent: 'Please choose an event',
    infoExternalEvent: 'Finances of an external event are not displayed',
    byCategoryType: 'By category type',
    byCategory: 'By category',
    byInscription: 'By inscription',
    accountBalances: 'Account balances',
    accountBalancesPerRider: 'Account balances per rider',
    totalForRider: "Total (from the rider's point of view: positive: credit, negative: open items)",
    filter: 'Filter',
    riderHasCredit: 'Rider has credit',
    riderOwsMoney: 'Rider owes money',
    balancingAccounts: 'Balancing accounts',
    licenseBookingsPerRider: 'License bookings by rider',
    necessaryBookings: 'Necessary bookings',
    createdBookings: 'Created bookings',
    recalculatePaymentStatus: 'Recalculate payment status',
    deleteLicenseBooking: (bookingName: string) => `Delete license booking ${bookingName}`,
    totalCreditOrganization: 'Total (credit organization)',
    totalOpenPayments: 'Open (outstanding payments)',
    overwritePaymentDate: (date: string) => `Payment date will be overwritten with ${date}`,
    paymentDateAlert:
      'The payment date of the bookings is not changed, the booking date in the XML is set to the next business day',
    summarizedPositionsForBilling: 'Summarized positions for billing',
    positionsForBilling: 'Positions for billing',
    recalculatePaymentStatusAlert:
      'Recalculate payment status if the payment status does not match the account balance. This will be calculated automatically in the future. It takes a few seconds.',
    addFineButtonText: 'Add fine',
    addFineTitle: 'Add fine',
    fine: 'Fine',
    bookingsOtherAssociations: 'Bookings of other associations',
    deleteBill: (transactionTitle: string) => `Delete invoice ${transactionTitle}`,
    reopenBill: 'Re-open invoice',
    deleteBooking: (transactionName: string) => `Delete booking ${transactionName}`,
    removeQrPayment: (transactionReference: string) =>
      `Remove QR payment ${transactionReference} from the user`,
    deleteQrPayment: (transactionReference: string) => `Delete QR payment ${transactionReference}`,
    qrPaymentNotice: 'QR payment was assigned manually => undo assignment',
    deletePaymentNotice: 'The payment will be completely deleted as if the import had not taken place',
    deleteAssignment: 'Delete / undo assignment',
    deletePayment: (paymentTitle: string) => `Delete ${paymentTitle}`,
    desiredDate: 'Desired date',
    requestedByUid: 'Requested by UID',
    paidByUid: 'Paid by UID',
  },
  accountingGroups: {
    sumNames: {
      open: 'Open',
      inscription: 'Inscription fees',
      donation: 'Donations',
      yearLicense: 'Year license fees',
      dayLicense: 'Day license fees',
      transponder: 'Sold transponders',
      automaticPayment: 'Automatic payments',
      manualBooking: 'Manual bookings',
      manualPayment: 'Manual payments',
      associationPayment: 'Payments to association',
    },
  },
  multipleEvents: 'multiple events',
  showOldTransactions: 'Show old transactions',
  overviewBox: {
    personalDataCompleted: 'Personal data filled out',
    requestLicense: (year: number) => `Fill out license request ${year}`,
    licenseRequestCompleted: 'License request filled',
    payMemberFees:
      'Pay the SAM member bill of the corresponding year (you have received an invoice by mail). The deposit by invoice takes a few days until it is credited to your account.',
    samMembershipPaid: 'SAM membership paid',
    payBills:
      'Pay open invoices (see below, under "Financial"). The deposit by invoice takes a few days until it is credited to your account.',
    noOpenLicenseFees: 'No open license invoice',
    addHealthCheck: 'Fill out health check',
    healthCheckCompleted: 'Health check completed',
    addInsuranceConfirmation: 'Fill out insurance confirmation',
    insuranceConfirmationUploaded: 'Insurance confirmation uploaded',
    uploadLicensePhoto: 'Capture photo',
    licensePhotoUploaded: 'Profile photo uploaded',
    bikeAdded: 'Bike added',
    adminConfirmationPending: 'Admin confirmation pending / on waiting list',
    adminConfirmationDone: 'Admin confirmation done',
    inscriptionSubmitted: 'Inscription submitted',
    inscriptionNotSubmitted: 'Inscription not submitted',
    inscriptionPaid: 'Inscription paid',
  },
  inscriptionBox: {
    tasksNotDone: "Your tasks haven't been fulfilled (see overview)",
    licenseNotApproved: 'License not approved yet',
    showAllCategories: 'Show all categories and category types',
    showAllCategoriesPerSportEvent: 'Inscribe with day license / club category',
    hideAllCategoriesPerSportEvent: 'Hide day licenses / club categories',
    showInscriptions: 'Show inscriptions',
    search: 'Search (event, association, category, ...)',
    inscriptionPending:
      '* PROVISIONALLY REGISTERED: please report to the inscription: not yet confirmed, or conditions not yet met (e.g. payment not yet received).',
  },
  otherSummary: 'Others',
  licensesBoxes: {
    invalidatedLicense: {
      title: 'Invalid license',
      explanation:
        'The license has been declared invalid by the association. If you have any questions, please contact the association.',
    },
    showLicensesBefore: (year: number) => `Show licenses before ${year}`,
    hideLicensesBefore: (year: number) => `Hide licenses before ${year}`,
    approvedLicensesTitle: 'Confirmed license requests',
    draftLicensesTitle: 'Requested licenses',
    preferredNumber: 'Preferred number',
    definitiveIssuedNumber: 'Definite issued number',
    provisionallyIssuedNumber: 'Provisional issued number',
    sidecarPartner: 'Sidecar partner',
    unknownCategory: 'Unknown category',
    requestNewLicense: (licenseYear: number) => `Request license(s) for ${licenseYear}`,
    requestAdditionalLicense: (licenseYear: number) => `Request more license(s) for ${licenseYear}`,
    requestLicenseOfDifferentYear: (licenseYear: number) =>
      `Wrong year? Request license for ${licenseYear}`,
    downloadLicense: 'Download license',
    licenseConfirmation: 'License confirmation',
    download: {
      license: 'Download license',
      pitLanePass: 'Download pit lane pass',
    },
    emergencyDetails: 'Emergency details',
    myLaps: 'MyLaps',
    organizerList: 'Organizer list',
    search: 'Search (name, category, #7, membership number, confirmed/new,',
    setSearchInfo: 'Assignment runs, can take a few minutes ...',
    assignmentInProgress: 'Assignment is running...',
    assignLicenseId: 'Assign license IDs',
    importLicenses: 'Import licenses',
    licensesToImport: 'Licenses to be imported',
    notFound: 'Not found:',
    overwrite: 'Overwrite',
  },
  personalDataBox: {
    title: 'Personal data',
    samMembershipNumber: 'SAM membership number',
    createPersonalData: 'Add personal data',
    changeEmail: {
      title: 'Change email',
      tryAgain: 'Try again',
      saving: 'Saving...',
      startEmailChangeTitle: 'Confirmation link sent',
      startEmailChangeBefore: 'We sent an e-mail to',
      startEmailChangeAfter: '. Please confirm this mail to start the e-mail change.',
      emailChangedTitle: 'Change email link sent',
      emailChangedBefore: 'We sent an e-mail to',
      emailChangedAfter: '. Please confirm the e-mail to complete the change.',
      securityHint: 'For security reason, you need to confirm the action in order to change your email.',
      confirmationEmailSent: 'Confirmation link sent',
      confirmWithGoogle: 'Confirm with Google',
      confirmWithFacebook: 'Confirm with Facebook',
    },
  },
  documentBox: {
    title: 'Documents',
    licensePhoto: 'Profile photo',
    contactOnAccident: 'Emergency details: contact in case of accident',
    emergencyDataMissing: 'Emergency details not yet recorded',
    healthCheck: 'Health check',
    insuranceConfirmation: 'Insurance confirmation',
    driversLicense: 'Drivers license',
  },
  driversLicense: {
    driversLicenseId: 'Drivers license ID',
  },
  optionalMessage: {
    title: 'Optional information',
    messagePart1: 'On the basis of your license(s), this information is',
    messagePart2Highlighted: 'optional',
    messagePart3:
      '. You can still enter the information here if you want to participate in an event where this information is required',
  },
  transactions: {
    name: 'Transactions (credits, stresses, payments, license bookings, inscription bookings)',
    transactionsByType: 'Transactions by booking type',
    downloadBill: 'Download the invoice',
    license: 'License request',
    inscription: 'Entry fee',
    inscriptionDayLicense: 'Entry fee (day license)',
    inscriptionDayCategory: 'Entry fee (single event)',
    power: 'Electricity',
    donation: 'Donation',
    inscriptionDiscount: '2-day-discount',
    mainLicenseName: 'License',
    additionalLicenseName: 'Additional license',
    discountName: 'Discount until',
    surchargeName: 'Surcharge after',
    reverse: 'Canceled:',
  },
  emergencyDetails: {
    title: 'Emergency details',
    titlePlural: 'Emergency details',
    editEmergency: 'Edit emergency details',
    addEmergencyDetails: 'Add emergency details',
    checkEmergencyDetails: 'Check emergency information form',
    emergencyDetailsChecked: 'Emergency information form checked',
    emergencyDetailsCompleted: 'Emergency details added',
    emergencyConfirmation: {
      label:
        'I confirm that I am insured sufficiently in an accident and have read the regulations of my category and appreciate. I expressly refrain from, in the event of an accident and / or claim of the organizing association / associations or its commissions and officials, the organizers, and other drivers or their supervisors or relatives, for any claims for any claims. I acknowledge that it is the responsibility of myself to secure myself privately for all sorts of claims (insure) or to carry the risk in full.',
      validation: 'The conditions must be accepted in order to participate in races',
    },
    seeEmergencyDetails: 'View emergency data',
    dataProtectionHint: 'This access is recorded for data protection reasons. Open?',
  },
  bikesBox: {
    frameNumber: 'Frame number',
    bikeMissing:
      "No bike added yet. To participate in a race, you have to add a bike here. At the moment, it's possible to add bikes for motocross and supermoto. Other category types will follow soon.",
    deleteBike: 'Delete bike',
    addNewBike: 'Add new bike',
  },
  delete: 'Delete',
  assign: 'assign',
  newMember: 'New member',
  previousLicenses: "Last year's licenses",
  buttons: {
    edit: 'Edit',
    add: 'Add',
    editEmail: 'Change email',
    cancel: 'Cancel',
    close: 'Close',
    back: 'Back',
    continue: 'Continue',
    save: 'Save',
    saving: 'Saving...',
    saveLicenseBooking: 'Save license booking',
    changeEmail: 'Change email',
    addObject: (object: string) => `Add ${object}`,
    addObjectOptional: (object: string) => `Add ${object} (optional)`,
    editObject: (object: string) => `Edit ${object}`,
    editObjectOptional: (object: string) => `Edit ${object} (optional)`,
    deleteButtonConfirmation: 'Delete?',
  },
  alerts: {
    dataSaved: 'Data saved',
    errorSaving: 'Error while saving',
    unknownError: 'unknown error',
  },
  after: 'after',
  licenseForm: {
    tooEarly: {
      title: "You're early",
      message:
        'The license form is not open yet. Please try again later. Follow us on social media to be informed about the go-live.',
    },
    mxrsSamFusionInfoP1:
      'MXRS and SAM sport have merged at the end of 2022. For the 2023 season, a new championship National Amateur Motocross Series (NAM) will be launched, where the MXRS and SAM categories will be merged into new categories.',
    mxrsSamFusionInfoP2:
      'If you are not yet a member of a SAM section, you can join an existing section, or register for the new section MXRS (this will follow in a next step in the license request).',
    mxrsSamFusionInfoP3:
      'The organizers in the background remain the same. Furthermore, you are additionally insured when solving a SAM license - it can therefore be that you do not need your existing additional insurance anymore.',
    mxrsSamFusionInfoP4:
      'We look forward to a successful season 2023, together with the organizers of the former MXRS and the new NAM series of the SAM.',
    mxrsSamFusionInfoP5: 'To do this, request a SAM license.',
    requestTransponders: 'Buy transponders',
    requestLicenses: 'Request licenses',
    requestLicense: 'Request license',
    total: 'Total',
    insuranceTerms: 'Details on the race accident insurance',
    searchCategories: 'Search category (e.g. MX2, "Youngster 85", Vintage 50+, ...)',
    searchCategoriesMin3Letters:
      'Search category, min. 3 letters (e.g. Swiss Moto, MX2, "Youngster 85", Vintage 50+, ...)',
    typeAtLeast3Letters: 'Type at least 3 letters to add more categories',
    advertiseSamInsurance: 'Insurance coverage by SAM',
    title: 'License Request',
    price: 'Price',
    steps: {
      personalData: {
        title: 'Personal data',
        samSectionChange: 'SAM section change',
      },
      lastSeason: 'Last season',
      categories: {
        title: 'Categories',
        includingInsurance: 'Incluces extended insurance coverage',
      },
      transponder: 'Transponder',
      additionalInfo: 'Further information',
      summary: {
        title: 'Summary',
        noBirthdateFound: 'Date of birth not entered, please complete your personal data',
        openCreditsAndDebits: 'Any outstanding credits or fines are not yet considered by the system.',
        category: 'Category',
        preferredNumber: 'Preferred number',
        priceChf: 'Price CHF',
        conclusion: 'Conclusion',
        alertTitleLicenseYear: (previousYear: number) =>
          `Attention: License request season ${previousYear}`,
        alertBodyLicenseYear: (nextYear: number, previousYear: number) =>
          `The license for the season ${nextYear} are not yet online. This license request is for the season ${previousYear}.`,
      },
      bikes: {
        title: 'Add bike for motocross/supermoto/minibike',
        alertTitleOtherCategoryTypes:
          'Other category types in addition to motocross, supermoto and minibike will be added later with specific field of the respective category type',
        alertBodyOtherCategoryTypes:
          'This form is currently only available for the category types motocross, supermoto and minibike because the first races are planned for these category types. The form for other category types will be online before their respective first race.',
        bikeAdded: 'Bike added',
        bikeNotSaved: 'Bike could not be saved',
        saveBike: 'Save bike',
      },
    },
  },
  licenseRequest: {
    title: 'License request',
    licenseRequestSent: 'License request sent',
    licenseRequestSentHint:
      'We have received your license request and we will process it soon. You can pay the license here as soon as we confirm it and enable the payment. You will receive a reminder email from us.',
  },
  emails: {
    greeting: 'Hello',
    bestWishes: 'Best wishes',
    signature: 'Your Racemanager Team',
    licenseRequestEmail: {
      title: 'License request received',
      text: 'Thank you for your license request. We will process your request and as soon as it has been accepted, your request will be confirmed and the corresponding bill will be issued. Please check back on your account from time to time to check on the status of your license.',
    },
    paymentReadyEmail: {
      oneLicenseConfirmedTitle: 'We have approved your license request and have confirmed your number',
      severalLicensesConfirmedTitle: 'We have',
      oneLicenseConfirmedNextFewDaysBefore: 'Yours',
      oneLicenseConfirmedNextFewDaysMiddle: 'yet unconfirmed',
      oneLicenseConfirmedNextFewDaysAfter: 'license is expected to get confirmed in the next few days.',
      multipleLicensesConfirmedNextFewDaysBefore: 'Your',
      multipleLicensesConfirmedNextFewDaysMiddle: 'yet unconfirmed',
      multipleLicensesConfirmedNextFewDaysAfter:
        'licenses are expected to get confirmed in the next few days.',
      insuranceInformation:
        'The beginning of the insurance coverage will be the date of payment. Starting on this day, the insurance coverage will last for 1 year, but at the longest until end of February of the year after. More information:',
      payLicenseBefore:
        'In order to get your license, you need to pay it. You can do this on the platform',
      payLicenseAfter: '.',
      numberInformation:
        'In the season 2022, the numbers on the bikes must conform with the Racemanager regulations. You can order a design that is compliant to rules at Backyard Racing. For more information and to order your decor, click here:',
      checkCurrentStatus:
        'You can access and edit the current status of your data and the status of your license request on the platform any time.',
      fillInEmergencyDetails:
        'As a next step, you can fill in the emergency detail form. You will find it on the platform as well.',
      number: 'Starting number',
      paymentLicenseRequestDueEndSingular: 'Payment license request due',
      paymentLicenseRequestDueDateEndPlural: 'Payment license requests due',
    },
  },
  licenseTasks: {
    names: {
      personalData: 'Personal data missing',
      licenseSubmitted: 'License application not sent',
      memberFees: 'SAM membership not paid',
      licenseFees: 'License not paid',
      checkEmergency: 'Emergency data not checked',
      emergency: 'Emergency data not entered',
      healthCheck: 'Health check not uploaded',
      insurance: 'Insurance confirmation not uploaded',
      photo: 'Profile photo not uploaded',
      inscriptionPayment: 'Inscription fee not paid',
      adminConfirmationPending: 'Not confirmed by admin/organizer yet, and possibly on waiting list',
      bike: 'No bike added',
      inscriptionSubmitted: 'Inscription not submitted',
    },
    shortNames: {
      personalData: 'Pers.',
      licenseSubmitted: 'Lic.Sub.',
      memberFees: 'MembUnp.',
      licenseFees: 'LicUnp.',
      emergency: 'Emerg.',
      checkEmergency: 'Emerg.Chk.',
      healthCheck: 'Heal.Chk.',
      insurance: 'Ins.',
      photo: 'Photo',
      inscriptionPayment: 'Unp.',
      adminConfirmationPending: 'AdmUnc.',
      bike: 'Bike',
      inscriptionSubmitted: 'Insc.',
    },
    pdfTasks: {
      emergency: 'Emergency data missing',
      checkEmergency: 'Emergency data not checked',
      healthCheck: 'Health check missing',
      insurance: 'Insurance confirmation missing',
      licenseFees: 'License not paid',
      licenseSubmitted: 'License request not sent',
      personalData: 'Personal data missing',
      memberFees: 'SAM membership not paid',
      photo: 'Profile photo is missing',
      inscriptionPayment: 'Inscription fee not paid',
      adminConfirmationPending: 'Admin confirmation pending / on waiting list',
      bike: 'Bike is missing',
      inscriptionSubmitted: 'Inscription not submitted',
    },
  },
  licensePdf: {
    valid: 'Valid',
    invalid: 'Invalid',
    validUntil: 'Date of expiry',
    notValidYet: 'Not yet valid',
    license: 'License',
    togetherWithIdOrPassport: '(together with ID or passport)',
    pitLane: 'Pit Lane',
    pitLaneDownload: 'Pit Lane Pass',
    notValidForEntry: "Gilt nicht als Eintritt. N'est pas une entrée valid. Not valid for entry.",
    rider: 'Rider',
    transponder: 'Transponder',
    trainingOnly: 'Valid: Training only',
    validNationalOnly: 'Valid: national only',
    validInternational: 'Valid: national / international',
    samDisclaimer:
      "MEDICAL INSURANCE\nThe holder of a SAM rider's licence is insured with respect to medical treatment according to the SAM rules.",
    fmsInternationalDisclaimerTitle: 'STARTING PERMISSION / AUTORISATION DE DÉPART',
    fmsInternationalDisclaimer:
      "The holder of a FIM rider/passenger's licence for International Meetings is granted STARTING PERMISSION for the disciplines mentioned on the front of the licence at all events approved by a member federation of the FIM/FIM Europe. He/she is insured with respect to medical treatment and repatriation according to the FIM/FIM Europe/Swiss Moto rules.\nThis STARTING PERMISSION is not valid for FIM-/FIM Europe-championships and - prizes.\nThe licence is valid only with signature of the rider/passenger",
    scanLicense: 'Scan License',
  },
  dayInscription: {
    approved: 'inscribed and confirmed',
  },
  description: 'Description',
  amountInChf: 'Amount in CHF',
  sportsEvent: {
    eventOrCategoryNotFound: 'Event or category not found',
    editSportEvent: (sportEvent: string) => `Edit ${sportEvent}`,
    sportEventsAdminForm: {
      categoryGroups: 'Number of groups (1 if empty) / max. riders per group (optional)',
      categoryGroupCounts: 'Number of groups',
      categoryGroupSizes: 'Riders per group',
    },
    errorsOccured: 'The following errors occurred',
    newEvent: 'New event',
    removeCategoryWarning:
      'Attention: Existing inscriptions will be automatically deleted when a category is removed.',
    changeCategoryDateWarning:
      'Attention: With certain constellations, only the Superadmin has the right to edit certain bookings. In this case, only the superadmin may change the date of the categories.',
    inscriptionWithoutDateNotice: 'If the date is not yet known, inscriptions are still possible.',
    changeEventDateNotice:
      'The date of registrations/inscriptions will be changed automatically when the date of the event or category is changed',
    referToDeletedOrMovedInscriptionTable:
      'See table "The following registrations are moved or deleted", which appears as soon as there is a change',
  },
  field: 'Field',
  import: 'Import',
  system: 'System',
  action: 'Action',
  documents: 'Documents',
  payments: {
    title: 'Payments',
    addManualPaymentTitle: 'Add manual payment',
    refund: 'Refund',
    addNewManualPayment: (paymentName: string) => `Add new manual ${paymentName}`,
    addManualPayment: (paymentName: string) => `Add ${paymentName}`,
    editManualPaymentTitle: 'Edit manual payment',
    editManualPayment: (paymentName: string) => `Edit manual ${paymentName}`,
    saveManualPayment: (paymentName: string) => `Save ${paymentName}`,
    renameTag: (tag: string) => `Rename tag ${tag}`,
    renameTagTo: (tag: string, newTag: string) => `Rename tag ${tag} to ${newTag}`,
    renameTagWarning: (tag: string, association: string) =>
      `Attention: All payments of every year with the tag ${tag} of the association ${association} will be renamed.`,
    sum: 'Total',
    invalidPayments: 'Invalid payments',
    noInvalidPaymentsFound: 'No invalid payments found',
    deleteQrPayment: 'Delete QR payment',
    invalidPayment: 'Invalid payment',
    searchPayments:
      'Search (name, membership number, zip code, location, MG-paid/mg-off/QR paid/QR-open)',
    bankPaymentUpload: 'Bank payments upload',
  },
  bills: {
    openBills: 'Open invoices',
    searchBillsLabel:
      'Search ("" amount "": 80, / country "": "" DE " / abroad / parts Reference number / name / street / zip code / location)',
    invalidEmptyZipCode: 'Invalid empty zip code',
    invalidSwissZipCode: 'Invalid Swiss zip code',
    invalidTooLongZip: 'Invalid too long zip code',
  },
  admins: {
    title: 'Admins',
    readOnlyAdmins: 'Read-only admin',
    adminEmail: 'Email (must be registered)',
    addAdmin: 'Add admin',
    addAsAdmin: 'Add as admin',
    confirmAddAdmin: (adminEmail: string) => `Add ${adminEmail} as admin?`,
    removeAdmin: 'Remove admin',
    removeAsAdmin: 'Remove as admin',
    confirmDeleteAdmin: (adminEmail: string) => `Delete ${adminEmail} as admin?`,
    associationAdmins: 'Association admins',
    addAssociationAdmin: (association: string) => `Add admin ${association}`,
    addAsAssociationAdmin: (association: string) => `Add as ${association} admin`,
    confirmAddAssociationAdmin: (adminEmail: string, association: string) =>
      `Add as ${adminEmail} ${association} admin?`,
    remove: 'Remove',
    removeAssociationAdmin: 'Remove association admin',
    removeAsAssociationAdmin: 'Remove as association admin',
    confirmRemoveAsAssociationAdmin: (adminEmail: string) =>
      `Delete ${adminEmail} as an association admin?`,
    disabledForAssociationAdmin:
      'This functionality is not (yet) available for the association admin role.',
    functionalityDeactivated: 'This functionality is deactivated.',
  },
  users: {
    title: 'User',
    loadMore: 'Load more',
    allUsersLoaded: 'All users loaded',
    editEmail: 'Edit email',
    newEmailSaved: 'New email saved',
    errorOnSaving: 'Error on saving',
    updateShortId: 'Update short UIDs (for QR code)',
    duplicatedUsersNotice:
      'Attention: This list is currently not updated automatically, but is reloaded every 2 minutes (user active / inactive / duplicate detection)',
    searchDuplicatedUsers: 'Search (name, place, zip code, membership number)',
    updatePersonalData: 'Edit personal data',
    editUserEmail: (email: string) => `Edit email ${email}`,
    loginAsUser: (user: string) => `Sign as user ${user}`,
  },
  uploads: {
    newlyUploaded: 'Newly uploaded',
    verified: 'Verified',
    invalidated: 'Invalidated',
    notUploadedDeleted: 'Not uploaded / deleted',
    noteForRider: 'Note for riders',
    internalNote: 'Internal note',
    uploadedOn: 'Uploaded on',
    size: 'Size',
    type: 'Type',
    resetToNewlyUploaded: 'Reset to newly uploaded',
    verify: 'Verify',
    invalidate: 'Invalid',
  },
  count: 'Count',
  chf: 'CHF',
  groupAssignments: {
    assignGroups: 'Assign groups',
    downloadChampionshipStandings:
      'The championship standings will be downloaded, and then the groups can be assigned',
    startGroupAssignment: 'Start group assignment',
    groupSizeAndStatistics: 'Group size & statistics',
    totalInscribed: 'Total (inscribed/registered)',
    numberOfGroups: 'x riders per group: groups count',
    configuredGroupSize: 'Configured group size',
    largestGroupInTheory: 'Theoretically largest group',
    withConfiguredGroupSize: 'with configured group size',
    deleteGroupAssignments: 'Delete all group assignments',
    deleteGroupAssignmentOf: (name: string) => `Delete group assignment of ${name}`,
    groupSize: 'Group size',
    listOfInscriptionsAndGroups: 'List of inscriptions and their groups',
    moveToHigherGroup: 'Move to higher group',
    confirmMoveToHigherGroup:
      'Really move to higher group? If the group does not exist, it will be created',
    moveToLowerGroup: 'Move to lower group',
    confirmMoveToLowerGroup: 'Really move to lower group?',
    ranking: (myLapsName: string) => `Ranking ${myLapsName}`,
    noRankingFound:
      'No championship found - will be assigned randomly. Alternatively, a ranking of the starting numbers (e.g. "23 91 7") can be specified.',
    assignAllGroups: 'Assign all groups',
    validInscriptionNoticeLong:
      'Only groups of valid inscriptions will be assigned. Existing groups will not be changed. Really assign?',
    validInscriptionNoticeShort:
      'Only groups of valid inscriptions will be assigned. Existing groups will not be changed.',
  },
}
